import React from 'react';
import { connect } from 'react-redux'
import Modal from 'react-modal';
import { Equation } from '../Equation';
import { TestResult } from './TestResult';
import { setTestResults } from '../../store/UserReducer';
import { AudioTypes, GameAudio } from '../GameAudio';

import './Test.scss';

const customStyles = {
  content : {
    width: '350px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid lightblue'
  }
};

class _Test extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      results: [],
      showInstructionModal: this.props.page.showInstructions,
      startTime: null,
      isDone: false,
      correct: 0,
      score: 0,
      elapsed: 0,
      audio: AudioTypes.intro,
      audioSrc: this.props.page.testAudio,
    }
  }

  onStartTest() {
    this.setState({
      ...this.state,
      showInstructionModal: false,
      startTime: new Date().getTime(),
      isDone: false,
      correct: 0,
      score: 0,
      elapsed: 0,
      audio: 'none'
    })
  }

  onDone() {
    console.log("TEST RESULTS: ", this.state.results);
    let nCorrect = 0;
    this.state.results.forEach(element => {
      if (element.valid)
        ++nCorrect;
    });
    console.log("nCorrect:", nCorrect);
    const now = new Date().getTime();
    const elapsed = Math.round((now - this.state.startTime) / 1000);
    console.log("elapsed time", elapsed);
    const score = nCorrect / this.props.page.testQuestions.length;
    this.setState({
      ...this.state,
      isDone: true,
      correct: nCorrect,
      score: score,
      elapsed: elapsed,
      audio: AudioTypes.done,
    });
    this.props.setTestResults(this.props.sectionId, nCorrect, score, elapsed);
    this.props.onLessonComplete();
  }

  render() {
    console.log("TEST page state", this.state);
    console.log("TEST page props", this.props);
    return (
      <div className="testContainer">
        <div className="instructions">
          {
            this.props.page.timeTest && this.state.isDone ?
              <React.Fragment>
                <TestResult
                  correct={ this.state.correct }
                  score={ this.state.score }
                  time={ this.state.elapsed }
                />
                <img src="assets/image/Teacher.png" alt=""/>
                <div>Click the NEXT button to proceed</div>
              </React.Fragment>
            :
              <React.Fragment>
                <ol>
                  <li> Click start to begin the test </li>
                  <li> Press TAB to move to the next problem. </li>
                  <li> Change an answer by clicking on it.</li>
                  <li> Click DONE to end the test.</li>
                </ol>
                <img src="assets/image/Teacher.png" alt=""/>
                <button onClick={() => this.onDone()}>done</button>
              </React.Fragment>
          }

        </div>
        <div className={`questions questions-${this.props.page.testQuestions.length}`}>
          {
            this.props.page.testQuestions.map((question, index) =>
              <div key={`test-question-${index}`}>
                <Equation
                  key={'eq'+index}
                  className="testEquation"
                  testComplete={ this.state.isDone }
                  equation={
                    {
                      x: { value: question.x },
                      y: { value: question.y }
                    }
                  }
                  reportResult={(result) => {
                    console.log("results: ", index, result);
                    let arr = this.state.results;
                    arr[index] = result;
                    this.setState({results: arr})
                  }}
                />
                <div className="correctAnswer">
                  {
                    !this.state.isDone ? ' ' : ( this.state.results[index] && this.state.results[index].valid ) ? ' ' : this.state.results[index] ? question.x*question.y : ' '
                  }
                </div>
              </div>
            )
          }
        </div>
          <Modal
            style={customStyles}
            isOpen={this.state.showInstructionModal}
            contentLabel="Test Instructions">
            <div>
              {this.props.page.instructions ? this.props.page.instructions.map(line => <><p>{line}</p></>) : ""}
            </div>
            <button className="startButton"
              onClick={() => this.onStartTest()}>
              START
            </button>
          </Modal>
          <GameAudio
            key={`testAudio`}
            src={[this.state.audioSrc]}
            type={this.state.audio} />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  setTestResults: (sectionId, correct, score, elapsed) => dispatch(setTestResults(sectionId, correct, score, elapsed)),
});

export const Test = connect(null, mapDispatchToProps)(_Test);
