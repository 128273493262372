import React from 'react';
import { TimesAlive } from './timesalive';
import './App.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSync, faArrowLeft, faArrowRight, faQuestion, faFolderOpen,
         faTimes, faVolumeMute, faVolumeDown, faPlay, faPause,
         faVideo, faMusic, faAdjust, faCheckCircle } from '@fortawesome/free-solid-svg-icons'
 import { faCircle, faFileAlt } from '@fortawesome/free-regular-svg-icons'
 library.add(faSync, faArrowLeft, faArrowRight, faQuestion, faFolderOpen,
            faTimes, faVolumeMute, faVolumeDown, faPlay, faPause, faVideo,
            faMusic, faFileAlt, faCircle, faAdjust, faCheckCircle);

const anchorStyle = {
  color: 'white',
  textDecoration: 'none'
};
function App() {
  console.log("App render");
  return (
    <div className="App">
      <header className="Page-header">
        <span className="one-third-row" />
        Online Times Alive Member's Area
      </header>
      <div className="logosContainer">
        <div className="City-creek-logo">
          <a href="https://www.citycreek.com" title="City Creek Press, Inc."  rel="noopener noreferrer" target="_blank">
            <img src="assets/image/city-creek-press-logo.png" alt="City Creek Press logo" title="City Creek Press, Inc." />
          </a>
        </div>
        <span className="logoSpan" />
        <img src="assets/Image/TimesAliveLogoGreen.png" className="TimesAliveLogo" alt="Times Alive logo" title="Times Alive" />
      </div>
      <div className="Times-Alive-container">
        <TimesAlive />
      </div>
      <div className="Post-ta-gap"></div>
      <div className="Page-footer">&nbsp;&nbsp;&nbsp;&#169; 2020 City Creek Press, Inc.</div>
    </div>
  );
}

export default App;
