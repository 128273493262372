import React from 'react'
import { SlidingHeader } from './SlidingHeader';
import { PickOne } from './PickOne';
import { MathProblem } from './MathProblem';
import './SlidingPicture.scss';

const pageTypes = {
  pickOne: 0,
  mathProblem: 1,
  done: 2
}

export class SlidingPicture extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: pageTypes.pickOne,
    }
  }

  onPageComplete = () => {
    if(this.state.page === pageTypes.mathProblem) {
      this.setState({ ...this.state, page: pageTypes.pickOne });
      this.props.onLessonComplete();
    }
    else
      this.setState({ ...this.state, page: this.state.page+1});
  }

  render() {
    console.log("Sliding Picture ", this.props);
    const correctAnswer = this.props.page.slides.find((slide) => slide.isCorrect === true);

    if(this.state.page === pageTypes.done)
      return (
        <div className="slidingContainer">lesson complete</div>
      );

    return (
      <div className="slidingContainer">
        <SlidingHeader 
          instructions={this.props.page.slideInstructions}
          hintText={this.props.page.slideHint } />
        {
          this.state.page === pageTypes.pickOne ? 
            <PickOne 
              onComplete={this.onPageComplete}
              
              audio={this.props.page.slideAudio[pageTypes.pickOne]}
              slides={this.props.page.slides}/>
          :
            <MathProblem 
              audio={this.props.page.slideAudio[pageTypes.mathProblem]}
              image={correctAnswer && correctAnswer.image}
              hint={this.props.page.slideHint}
              x={this.props.page.x}
              y={this.props.page.y}
              onComplete={this.onPageComplete}/>
        }
      </div>
    );
  }
}
