
export default class Progress {

  static getDefault() {
    //TODO: This is temporary!!!
    // We should be getting this from an API.
    return [
      {
        section: "1a",
        status: "",         // <empty>|viewed|completed
        completedOn: null,  // date
        testResults: {      // either an object or null for a non-test type
          correct: null,      // either null or a number
          score: null,        // either null or a percentage value
          time: null,         // either null or the elapsed time to take the test
        }
      },
      {
        section: "1b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "1c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "2a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "2b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "2c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "3a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "3b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "3c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "4a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "4b",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "5a",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "5b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "5c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "5d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "6a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "6b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "6c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "6d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "7a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "7b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "7c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "8a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "8b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "8c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "8d",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "9a",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "9b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "9c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "9d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "10a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "10b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "10c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "10d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "11a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "11b",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "11c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "11d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "11e",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "12a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "12b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "12c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "13a",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "13b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "13c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "13d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "14a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "14b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "14c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "14d",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "14e",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "15a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "15b",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "15c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "15d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "15e",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "16a",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "16b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "16c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "16d",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "17a",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      },
      {
        section: "17b",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "17c",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "17d",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "17e",
        status: "",
        completedOn: null,
        testResults: null
      },
      {
        section: "18a",
        status: "",
        completedOn: null,
        testResults: {
          correct: null,
          score: null,
          time: null,
        }
      }
    ];
  }

  static getSection(data, sectionId) {
    return data.find(section => section.section === sectionId);
  }

}
