import React from 'react';
import { connect } from 'react-redux';
import { getProgress, getCurrentUserDbId } from '../store/UserReducer';
import Api from '../Api'

class _ProgressMonitor extends React.Component {

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.progress !== this.props.progress && prevProps.userId === this.props.userId) {
      console.log("Saving Progress");
      Api.saveProgress(this.props.userId, this.props.progress);
    }
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({
  userId: getCurrentUserDbId(state),
  progress: getProgress(state),
});

export const ProgressMonitor = connect(mapStateToProps, null)(_ProgressMonitor)
