import React from 'react';
import { RoundButton } from './RoundButton';

export class ReplayButton extends React.Component {

  render() {
    return (
      <RoundButton 
        className="replayBtn"
        toolTip="Repeat the lesson"
        icon="sync"
        { ...this.props }>
        do again
      </RoundButton>
    )
  }
}
