import React from 'react';
import { combineClasses } from '../util';
import './Equation.scss';

/*
  props:
    reportResult(): function return an object { valid: bool, answer: their answer}
    text: 
    direction: "row | column", if none specificied uses column
    equation {
      x: {value: #, input: true|false}
      y: {value: #, input: true|false}
    }
    // input true means show input box, false means show the static value
    className: string, style
    testComplete: if true show the answer in red if incorrect 
    showInvalid: if true wrong answers (inputs) are red if incorrect
*/
const eqSection = {
  x : 'x',
  y : 'y',
  answer : 'answer'
}
export class Equation extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      x: props.equation.x.input ? '' : props.equation.x.value,
      y: props.equation.y.input ? '' : props.equation.y.value,
      answer: '',
    }
  }

  answerValid = () => {
    // these are our desired values
    const x = this.props.equation.x.value;
    const y = this.props.equation.y.value;
    const answer = x*y;
    return answer === parseInt(this.state.answer, 10);
  }

  xValid = () => {
    // these are our desired values
    const x = this.props.equation.x.value;
    const y = this.props.equation.y.value;
    // the student's answers
    const ansX = parseInt(this.state.x, 10);

    if( x === y ) {
      return ansX === x;
    }

    return (ansX === x || ansX === y)
//    if( (x === ansX && y === ansY) || (x === ansY && y === ansX) ) {
  }

  yValid = () => {
    // these are our desired values
    const x = this.props.equation.x.value;
    const y = this.props.equation.y.value;
    // the student's answers
    const ansX = parseInt(this.state.x, 10);
    const ansY = parseInt(this.state.y, 10);

    if( x === y ) {
      return ansY === y;
    }

    // x arbitrarily gets priority
    if(this.xValid())
      // if x is valid then y has to be the other one
      return ( ansX !== ansY && (ansY === x || ansY === y))
    else
      return (ansY === x || ansY === y)
    
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.state !== prevState) {

      if(!this.answerValid()) {
        this.props.reportResult({valid: false, answer: this.state.answer});
        return;
      }

      if( this.yValid() && this.yValid() )
        this.props.reportResult({valid: true, answer: this.state.answer});
      else
        this.props.reportResult({valid: false, answer: this.state.answer});
    }
  }

  showInvalid = (section) => {
    if(this.props.showInvalid) {
      if(section === eqSection.answer && !this.answerValid()) {
        return 'invalidAnswer';
      }
      if(section === eqSection.x && !this.xValid()) {
        return 'invalidAnswer';
      } 
      if(section === eqSection.y && !this.yValid()) {
        return 'invalidAnswer';
      } 
    }
    
    return null;
  }

  render() {
    const {x, y} = this.props.equation;
    return (
      <div className={combineClasses("equationContainer", this.props.className)}>
        <div className={ this.props.direction === "row" ? "equationRow" : "equationColumn"}>
          <label>{this.props.text ? this.props.text : ''}</label>
          {
            x.input ?
              <input maxLength="2" type="text"
                value={this.state.x}
                className={ this.showInvalid(eqSection.x) }
                onChange={ (event) => this.setState({...this.state, x: event.target.value}) }
              />
            :
              <label> {x.value} </label>
          }
          <div className="times">
            <label> x </label>
            {
              y.input ?
                <input maxLength="2" type="text"
                  value={this.state.y}
                  className={ this.showInvalid(eqSection.y) }
                  onChange={ (event) => this.setState({...this.state, y: event.target.value}) }
                />
              :
                <label> {y.value} </label>
            }
          </div>
          { this.props.direction === "row" && <label> = </label> }
          {
            this.props.testComplete
            ?
              <div className={ this.state.x*this.state.y === parseInt(this.state.answer, 10) ? "finalAnswer" : "finalAnswer finalAnswerWrong" }>
                { this.state.answer }
              </div>
            :
              <input maxLength="2" type="text"
              className={ this.showInvalid(eqSection.answer) }
              value={this.state.answer}
              onChange={ (event) => this.setState({...this.state, answer: event.target.value}) }
              />
          }

        </div>
      </div>
      )
  }

}