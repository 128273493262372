import React from 'react'
import { GuessWithPicture } from './GuessWithPicture';
import { GuessNoPicture } from './GuessNoPicture';
import './GuessingGame.scss';

const pageTypes = {
  withPicture: 0,
  noPicture: 1,
  done: 2
}

export class GuessingGame extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      page: pageTypes.withPicture,
    }
  }

  onPageComplete = () => {
    if(this.state.page === pageTypes.noPicture) {
      console.log("Guessing Game Complete ");
      this.props.onLessonComplete();
    }
    else
      this.setState({ ...this.state, page: this.state.page+1});
  }

  render() {
    if(this.state.page === pageTypes.done)
      return (
        <div className="guessingContainer">
        lesson complete</div>
      )

    return (
      <div className="guessingContainer">
        {
          this.state.page === pageTypes.withPicture ?
            <GuessWithPicture
              image={this.props.page.gameImage}
              x={this.props.page.x}
              y={this.props.page.y}
              audio={this.props.page.gameAudio[0]}
              onComplete={this.onPageComplete}/>
          :
            <GuessNoPicture
              x={this.props.page.x}
              y={this.props.page.y}
              audio={this.props.page.gameAudio[1]}
              onComplete={this.onPageComplete}/>
        }
      </div>
    )
  }
}
