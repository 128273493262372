import React from 'react';
import { connect } from 'react-redux'
import { getCurrentLesson, getLessonPage } from '../../store/LessonReducer';
import { getPageType } from '../../store/UiReducer';

export class _PrintTestButton extends React.Component {
    printWindow = (lessonName) => {
      const currentTitle = document.title;
      document.title = lessonName;
      window.print();
      document.title = currentTitle;
    }

    render() {
    const page = this.props.currentLesson.pages[this.props.lessonPage];
    const lessonName = this.props.currentLesson.name;
    console.log("_PrintTestButton", this.props)
    return (
      <div>
        { this.props.currentPageType === 'lesson'
          && page && page.type === 'test'
          && page.testQuestions && page.testQuestions.length > 4 &&
          <button
            className="printTestBtn"
            onClick={() => this.printWindow(lessonName)}>
            print blank test
          </button>
        }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentLesson: getCurrentLesson(state),
  lessonPage: getLessonPage(state),
  currentPageType: getPageType(state),
});

export const PrintTestButton = connect(mapStateToProps, null)(_PrintTestButton);
