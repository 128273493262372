import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {lessonTypes} from '../data';
import { combineClasses } from '../util';

/*
  props:
    lessonType: string
    className: string
*/

export const LessonIcon = (props) => {

  return (
    <div className={combineClasses("lessonIconContainer", props.className)}>
      <FontAwesomeIcon 
        icon={ lessonTypes[props.lessonType].icon } 
        className="icon" />
    </div>
  )
}