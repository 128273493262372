import Progress from '../Progress'

const initialState = {
  progress: Progress.getDefault(),
  username: '',
  userDbId: undefined,
  teacherId: '',
  teacherDbId: undefined
}

const actionTypes = {
  SET_PROGRESS: "SET_PROGRESS",
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_CURRENT_USER_DB_ID: "SET_CURRENT_USER_DB_ID",
  SET_TEACHER_ID: "SET_TEACHER_ID",
  SET_TEACHER_DB_ID: "SET_TEACHER_DB_ID",
  UPDATE_SECTION_STATUS: "UPDATE_SECTION_STATUS",
  UPDATE_SECTION_RESULTS: "UPDATE_SECTION_RESULTS",
  CLEAR_USER: "CLEAR_USER"
}


export const setProgress = (progress) => {
  return {
    type: actionTypes.SET_PROGRESS,
    payload: progress
  };
}

export const setCurrentUser = (username) => {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: username
  }
}

export const setCurrentUserDbId = (id) => {
  return {
    type: actionTypes.SET_CURRENT_USER_DB_ID,
    payload: id
  }
}

export const setTeacherId = (teacherId) => {
  return {
    type: actionTypes.SET_TEACHER_ID,
    payload: teacherId
  }
}

export const setTeacherDbId = (id) => {
  return {
    type: actionTypes.SET_TEACHER_DB_ID,
    payload: id
  }
}

export const markSectionViewed = (sectionId) => {
  return {
    type: actionTypes.UPDATE_SECTION_STATUS,
    payload: {
      sectionId: sectionId,
      status: "viewed"
    }
  }
}

export const markSectionComplete = (sectionId) => {
  return {
    type: actionTypes.UPDATE_SECTION_STATUS,
    payload: {
      sectionId: sectionId,
      status: "completed",
      completedOn: Date.now(),
    }
  }
}

export const setTestResults = (sectionId, correct, score, elapsed) => {
  return {
    type: actionTypes.UPDATE_SECTION_RESULTS,
    payload: {
      section: sectionId,
      testResults: {
        correct: correct,
        score: score,
        time: elapsed,
      },
      status: "completed",
      completedOn: Date.now(),
    }
  }
}

export const clearTestResults = (sectionId) => {
  console.log("CLEAR TEST RESULTS FOR ", sectionId);
  return {
    type: actionTypes.UPDATE_SECTION_RESULTS,
    payload: {
      section: sectionId,
      testResults: {
        correct: null,
        score: null,
        time: null,
      },
      status: "",
      completedOn: null,
    }
  }
}

export const clearUser = () => {
  return {
    type: actionTypes.CLEAR_USER
  }
}

// the reducer
const userReducer = (state = initialState, action) => {
  switch(action.type) {
      case actionTypes.SET_CURRENT_USER:
        return {
          ...state,
          username: action.payload
        }
      case actionTypes.SET_CURRENT_USER_DB_ID:
        return {
          ...state,
          userDbId: action.payload
        }
      case actionTypes.SET_TEACHER_ID:
        return {
          ...state,
          teacherId: action.payload
        }
      case actionTypes.SET_TEACHER_DB_ID:
        return {
          ...state,
          teacherDbId: action.payload
        }
      case actionTypes.SET_PROGRESS:
        return {
          ...state,
          progress: action.payload
        };
      case actionTypes.UPDATE_SECTION_STATUS:
        let section = Progress.getSection(state.progress, action.payload.sectionId);
        section.status = action.payload.status;
        if(action.payload.completedOn !== undefined)
          section.completedOn = action.payload.completedOn;
        return {
          ...state,
          progress: state.progress.map(p => p.section === action.payload.sectionId ? section : p )
        };
      case actionTypes.UPDATE_SECTION_RESULTS:
        return {
          ...state,
          progress: state.progress.map(p => p.section === action.payload.section ? action.payload : p )
        };
      case actionTypes.CLEAR_USER:
        return initialState;
      default:
        return state;
  }
}

const getUserReducer = state => state.USER;

export const getCurrentUser = state => getUserReducer(state).username;
export const getCurrentUserDbId = state => getUserReducer(state).userDbId;
export const getTeacherId = state => getUserReducer(state).teacherId;
export const getTeacherDbId = state => getUserReducer(state).teacherDbId;
export const getProgress = state => getUserReducer(state).progress;
export const getSectionStatus = (state, sectionId) => {
  const section = Progress.getSection(getUserReducer(state).progress, sectionId);
  if (section) {
    return section.status;
  }
  return null;
}
export const getSectionResults = (state, sectionId) => {
  const section = Progress.getSection(getUserReducer(state).progress, sectionId);
  if (section) {
    return section;
  }
  return null;
}

export const reducer = { USER: userReducer }
