import React from 'react';

/* 
  props:
    instructions: text
    hintText: text
*/

export class SlidingHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showHint: false
    }
  }

  onHint() {
    this.setState({showHint: true});
    setTimeout(() => {
      this.setState({showHint: false});
    }, 10000);
  }

  render() {
    return (
      <div className="instructions">
        <div>
        {
          this.state.showHint
          ?
            <label>{this.props.hintText}</label>
          :
          <React.Fragment>
            <label>{this.props.instructions}</label>
            <label>Click on the picture for the story.</label>
          </React.Fragment>
        }
        </div>
        <button onClick={() => this.onHint()}> HINT </button>
      </div>
    );
  }
}