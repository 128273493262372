import React from 'react';
import { connect } from 'react-redux';
import { getMute } from '../store/UiReducer';
import { randomNumber } from '../util';
import './AudioPlayer.scss';

const AudioPlayerMode = {
  'random': 'random',
  'queue': 'queue'
}

/* 
  props:
    src: [] of audio files
    onAudioComplete: callback fn
*/

export const AudioPlayerRandom = (props) => {
  return (
    <AudioPlayer mode={AudioPlayerMode.random} {...props}/>
  )
}

export const AudioPlayerQueue = (props) => {
  return (
    <AudioPlayer mode={AudioPlayerMode.queue} {...props}/>
  )
}

/* 
  props:
    src: [] of audio files
    type: 'random' or 'queue', default is queue
    onAudioComplete: callback fn
*/

class _AudioPlayer extends React.Component {

  constructor(props) {
    super(props);
    this.audioRef = React.createRef();

    this.state = {
      currentAudio: this.props.mode === AudioPlayerMode.random ? this.pickRandom() : 0
    }
  }

  pickRandom() {
    if(!this.props.src)
      return 0;
    return randomNumber(0, this.props.src.length-1);
  }

  onEnded() {
    if(this.props.mode === AudioPlayerMode.random) {
      this.setState({currentAudio: this.props.src.length});
    } else {
      this.setState({currentAudio: this.state.currentAudio+1});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.isMute && prevProps.isMute !== this.props.isMute)
      this.setState({currentAudio: this.props.src.length});
    
    if(this.props.onAudioComplete && prevState.currentAudio !== this.state.currentAudio  && this.state.currentAudio === this.props.src.length)
      this.props.onAudioComplete();
  }

  componentDidMount() {
    if(this.props.isMute)
      setTimeout(() => {
        this.setState({currentAudio: this.props.src.length});
      }, 1000);
  }

  render() {

    if(!this.props.isMute && this.props.src && this.state.currentAudio < this.props.src.length)
      return (
        <div className="audioContainer" key={`audio-ctrl-${this.props.src[this.state.currentAudio]}`}>
          <audio
            controls
            onEnded={() => this.onEnded()}
            ref={this.audioRef} 
            src={`assets/audio/${this.props.src[this.state.currentAudio]}`} 
            type="audio/mpeg" 
            autoPlay />
          </div>
      );

    return null;
  }
  
}

const mapStateToProps = (state) => ({
  isMute: getMute(state)
});

export const AudioPlayer = connect(mapStateToProps, null)(_AudioPlayer)
