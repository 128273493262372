import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { RoundButton } from './RoundButton';
import { setPageType } from '../../store/UiReducer';
import { clearUser } from '../../store/UserReducer';
import { pageTypes } from '../../data'
import "../LessonList/Lesson.scss";

const quitDialogStyles = {
  content : {
    width: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #0d2d70',
    fontSize: "20pt",
    fontWeight: "600"
  }
};

export class _QuitButton extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showDialogModal: false
    };
  }

  onClick = () => {
    this.setState({
      ...this.state,
      showDialogModal: true
    });
  }

  onYes = () => {
    console.log("Yes button clicked");
    this.setState({
      ...this.state,
      showDialogModal: false
    });
    this.props.clearUser();
    this.props.gotoLoginPage();
  }
  onNo = () => {
    console.log("No button clicked");
    this.setState({
      ...this.state,
      showDialogModal:false
    })
  }

  render() {
    return (
      <div>
      <RoundButton
        className="quitBtn"
        toolTip=" Quit the program"
        icon="times"
        onClick= {this.onClick}
        { ...this.props }>
        quit
      </RoundButton>
      <Modal
        style={quitDialogStyles}
        isOpen={this.state.showDialogModal}
        >
          <div className="dialogText">
            Do you want to quit?<br />
          </div>
          <div className="buttonRow">
            <button className="yesButton" onClick={this.onYes}>
              Yes
            </button>
            <button className="noButton" onClick={this.onNo}>
              No
            </button>
          </div>
      </Modal>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  gotoLoginPage: () => dispatch(setPageType(pageTypes.login)),
  clearUser: () => dispatch(clearUser())
});

export const QuitButton = connect(null, mapDispatchToProps)(_QuitButton)
