import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { setCurrentUser, setProgress, setCurrentUserDbId, setTeacherDbId } from '../store/UserReducer';
import Api from '../Api';
import Progress from '../Progress';
import './LoginPage.scss';

const loginDialogStyles = {
  content : {
    width: '400px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #0d2d70',
  }
};

export class _LoginPage extends React.Component {
  constructor(props) {
    super(props);
    console.log("LoginPage", props);

    this.state = {
      teacherId: '',
      userName: '',
      showLoginModal: false,
      showSubscribeModal: false,
      error: false,
      errorMessage: ''
    };
  }

  closeDialog = () => {
    this.setState({...this.state, showLoginModal: false, errorMessage: ''});
  }

  showError = (text, focusId) => {
    document.getElementById(focusId).focus();
    this.setState({...this.state, errorMessage: text, error: true})
  }

  hideError = () => {
    this.setState({...this.state, errorMessage: '', error: false});
  }

  showNoSubscription = () => {
    this.setState({...this.state, showSubscribeModal: true})
  }

  hideNoSubscription = () => {
    this.setState({...this.state, showSubscribeModal: false})
  }

  onSubmit = (event) => {
    console.log("onSubmit, event", event);
    this.hideError();
    if (this.state.teacherId.trim() === '') {
      event.preventDefault();
      event.stopPropagation();
      this.showError("Type your member's email or username", "teacher-input");
    }
    else if (this.state.userName.trim() === '') {
      event.preventDefault();
      event.stopPropagation();
      this.showError("Type the student's full name", "login-input");
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      try {
        console.time("checkSubscription");
        const status = Api.checkSubscription(this.state.teacherId.trim());
        console.timeEnd("checkSubscription");
        if (status) {
          if (status.success && (   status.status === "Active"
                                 || status.status === "Pending Cancellation"
                                 || status.status === "Free Trial"
                                 || status.status === "Complimentary")) {
            console.time("loginUser");
            const user = Api.loginUser(status.email, this.state.userName.trim());
            console.timeEnd("loginUser");
            console.log("LOGIN GOT A USER ", user);
            if (user.teacherId) {
              if (user.studentId) {
                this.setState({
                  ...this.state,
                  showLoginModal: false
                });
                this.props.setUser(user.name);
                this.props.setUserDbId(user.studentId);
                this.props.setTeacherDbId(user.teacherId);
                this.props.setProgress(user.progress);
                this.props.newUserLogin(this.state.teacherId, this.state.userName);
              }
              else {
                this.showError("Unknown Student or Member", "teacher-input");
              }
            }
            else {
              this.showError("Unknown Member", "teacher-input");
            }
          }
          else {  // subscription status is not "Active"
            this.showNoSubscription();
          }
        }
        else {  // API call to get subscription status failed
          this.showNoSubscription();
        }

      }
      catch (error) {
        console.log("caught error:", error);
        this.showError("Are you sure you're connected to the internet?", "teacher-input");
      }
    }
  }

  onTryIt = () => {
    this.props.setProgress(Progress.getDefault());
    this.props.newUserLogin('');
  }

  onLogin = () => {
    this.setState({
      ...this.state,
      showLoginModal: true
    })
  }

  onChangeTeacherId = (event) => {
    //console.log("LoginPage onChangeTeacherId", event.target.value);
    this.setState({teacherId: event.target.value});
  }
  onChangeUserName = (event) => {
    //console.log("LoginPage onChangeUserName", event.target.value);
    this.setState({userName: event.target.value});
  }

  render() {
    console.log("LoginPage render()")
    return (
      <div>
        <img src="assets/image/LoginLogo.png" alt="" />
        <div className="buttonRow">
          <button className="loginButton" onClick={this.onLogin}>
            Login
          </button>
          <button className="tryitButton" onClick={this.onTryIt}>
            Try it
          </button>
        </div>
        <Modal
          style={loginDialogStyles}
          isOpen={this.state.showLoginModal}
        >
          <div>
            <span className="closeX" onClick={this.closeDialog}>&times;</span>
            <form>
              <label className="loginLabel">Member's Email or Username</label><br/>
              <input
                id="teacher-input"
                className="loginInput"
                type="text"
                autoFocus
                autoComplete="off"
                value={this.state.teacherId}
                onChange={this.onChangeTeacherId} /><br/><br/>
              <label className="loginLabel">Student's Name</label><br/>
              <input
                id="login-input"
                className="loginInput"
                type="text"
                autoComplete="off"
                value={this.state.userName}
                onChange={this.onChangeUserName} /><br/><br/>
              <div className="modalButtonRow">
              <input
                  className="loginEnterButton"
                  type="submit"
                  value="ENTER"
                  onClick={this.onSubmit} />
                {
                  this.state.error &&
                  <div className="fullnamePrompt" id="error-msg">{this.state.errorMessage}</div>
                }
              </div>
            </form>
          </div>
        </Modal>
        <Modal
          style={loginDialogStyles}
          isOpen={this.state.showSubscribeModal}
        >
          <div className="notSubscribed">
            OOPS! We don’t have a membership for that email address. Try again or sign up at
            <a href="https://www.citycreek.com/shop" title="City Creek Press, Inc."  rel="noopener noreferrer" target="_blank" onClick={this.hideNoSubscription}> https://www.citycreek.com/shop</a>.<br /><br />
          </div>
          <button className="closeButton" onClick={this.hideNoSubscription}>Close</button>
        </Modal>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  setUser: (userName) => dispatch(setCurrentUser(userName)),
  setProgress: (progress) => dispatch(setProgress(progress)),
  setUserDbId: (id) => dispatch(setCurrentUserDbId(id)),
  setTeacherDbId: (id) => dispatch(setTeacherDbId(id))
});

export const LoginPage = connect(null, mapDispatchToProps)(_LoginPage)
