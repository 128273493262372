import React from "react";
import './App.css';
import { connect } from 'react-redux'
import { pageTypes } from './data';
import { IntroMovie, ButtonToolbar, LessonList, Lesson, ProgressMonitor,
         Header, TeacherDirections, ProgressReport } from './components';
import { LoginPage } from './pages';
import { getPageType, setPageType } from './store/UiReducer';
import { getCurrentLesson, getLessonPage, setCurrentLessonAndPage, 
         advanceLesson, retreatLesson } from './store/LessonReducer';
import { setCurrentUser, setTeacherId, getCurrentUserDbId } from './store/UserReducer'

class _TimesAlive extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      lessonTag: 100
    }
  }
  onIntroComplete = () => {
    console.log("Intro Movie complete");
    this.props.setPageType(pageTypes.login);
  }

  onUserLogin = (teacherId, user) => {
    console.log("User Logged in", teacherId, user);
    this.props.setPageType(pageTypes.lessonList);
  }

  onLessonSelected = (id) => {
    console.log("Lesson " + id + " selected");
    this.props.setLessonAndPage(id, 0);
    this.props.setPageType(pageTypes.lesson);
  }

  onBack = () => {
    console.log("back button!");
    console.log("pageType", this.props.currentPageType);
    switch (this.props.currentPageType) {
      case pageTypes.login:
        this.props.setPageType(pageTypes.introMovie);
        break;
      case pageTypes.lesson: {
        this.props.retreatLesson(this.props.currentLesson.id, this.props.lessonPage);
        break;
      }
      case pageTypes.teacherDirections:
        this.props.setPageType(pageTypes.lessonList);
        break;
      default:
        // do nothing
    }
  }

  onNext = () => {
    console.log("next button!");
    console.log("props", this.props);
    switch (this.props.currentPageType) {
      case pageTypes.lesson:
        this.props.advanceLesson(this.props.currentLesson.id, this.props.lessonPage);
        break;
      case pageTypes.introMovie:
        this.props.setPageType(pageTypes.login);
        break;
      default:
        //
    }
  }

  onLessonList = () => {
    console.log("LessonList button!");
    this.props.setPageType(pageTypes.lessonList);
  }

  onTeacherInstruction = () => {
    console.log("TeacherInstr button!");
    this.props.setPageType(pageTypes.teacherDirections);
  }

  onProgressReport = () => {
    console.log("Progress Report button!");
    this.props.setPageType(pageTypes.progressReport);
  }

  onReplay = () => {
    console.log("On Replay!", this.state.lessonTag);
    this.setState({lessonTag: this.state.lessonTag+1});
  }

  renderPage = () => {
    console.log("currentPage: " + this.props.currentPageType);
    switch(this.props.currentPageType) {
        case pageTypes.introMovie:
          return (<IntroMovie onComplete={this.onIntroComplete}/>);
        case pageTypes.login:
          return (<LoginPage newUserLogin={this.onUserLogin} />);
        case pageTypes.lessonList:
          return (<LessonList onLessonSelected={this.onLessonSelected} onTeacherInstruction={this.onTeacherInstruction} />);
        case pageTypes.lesson:
          return (<Lesson key={`lesson-${this.props.currentLesson.id}-${this.state.lessonTag}`} lessonId={this.props.currentLesson}/>);
        case pageTypes.teacherDirections:
          return (<TeacherDirections />);
        case pageTypes.progressReport:
          return (<ProgressReport />);
        default:
          return (<IntroMovie />);
      }
  }

  render() {
    return (
      <div className="Times-Alive">
        {
          this.props.userId && <ProgressMonitor />
        }
        <div className="Times-tables-the-fun-way"><img src="assets/image/Header.png" alt="Times Tables the Fun Way!" /></div>
        <Header />
        <div className="Times-Alive-lessons-area">
          {
            this.renderPage()
          }
        </div>
        <div className="button-toolbar-area">
          <ButtonToolbar
            currentPage={this.props.currentPageType}
            onBack={this.onBack}
            onNext={this.onNext}
            onLessonList={this.onLessonList}
            onProgressReport={this.onProgressReport}
            onReplay={this.onReplay}
            />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentLesson: getCurrentLesson(state),
  lessonPage: getLessonPage(state),
  currentPageType: getPageType(state),
  userId: getCurrentUserDbId(state)
});

const mapDispatchToProps = (dispatch) => ({
  setLessonAndPage: (lessonId, pageIndex) => dispatch(setCurrentLessonAndPage(lessonId, pageIndex)),
  setPageType: (pageType) => dispatch(setPageType(pageType)),
  advanceLesson: (lessonid, pageid) => dispatch(advanceLesson(lessonid, pageid)),
  retreatLesson: (lessonid, pageid) => dispatch(retreatLesson(lessonid, pageid)),
  setTeacherId: (teacherId) => dispatch(setTeacherId(teacherId)),
  setCurrentUser: (user) => dispatch(setCurrentUser(user))
});

export const TimesAlive = connect(mapStateToProps, mapDispatchToProps)(_TimesAlive)
