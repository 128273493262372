import React from 'react';
import { Equation } from '../Equation';
import { AudioTypes, GameAudio } from '../GameAudio';
import { randomNumber } from '../../util';

/*
  props: 
    onComplete: function callback
    image : image to show
    instructions: text
    audio: audio file
    x & y : the math question, ie 3 x 4
*/

export class MathProblem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isValid: false,
      showInvalid: false,
      audio: AudioTypes.intro,
      audioKey: randomNumber(0,1000) // without this subsequent queues to wrong answer won't play
    }
  }

  validateAnswer = () => {
    if(this.state.isValid) 
      this.setState({audio: AudioTypes.correct, audioKey: randomNumber(0,1000)});
    else 
      this.setState({showInvalid: true, audio: AudioTypes.wrong, audioKey: randomNumber(0,1000)});
  }

  onAudioComplete() {
    if(this.state.audio === AudioTypes.correct)
      this.props.onComplete();
  }

  render() {
    const imageFile = "assets/image/" + this.props.image;

    return (
      <div className="mathProblem">
        <div className="picture">
          <img src={imageFile} alt="TODO" />
        </div>
        <div className="problem">
          <Equation
            className="slideEquation"
            direction="column"
            equation={
              {
                x: { value: this.props.x, input: false },
                y: { value: this.props.y, input: false }
              }
            }
            reportResult={(result) => this.setState({isValid: result.valid})}
            showInvalid={this.state.showInvalid}
            />
          
          <button onClick={() => this.validateAnswer()}>Check</button>
        </div>
        <GameAudio 
          key={`ggAudio-${this.state.audioKey}`} 
          src={[this.props.audio]} 
          type={this.state.audio} 
          onAudioComplete={() => this.onAudioComplete()}/>
      </div>
    )
  }
}
