import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getShowHelp } from '../../store/UiReducer';
import './RoundButton.scss';

const _RoundButton = (props) => {
  return (
    <div className="roundButtonContainer">
      <div className="buttonWrapper">
        <button
          className={props.className}
          onClick={props.onClick}
          disabled={props.disabled}>
          {
            props.icon &&
            <FontAwesomeIcon icon={props.icon} className="icon"/>
          }
          </button>
        <label>{props.children}</label>
      </div>
      {props.toolTip && props.showHelp && <div className="tooltiptext">{props.toolTip}</div>}
    </div>
  )
}

const mapStateToProps = (state) => ({
  showHelp: getShowHelp(state)
});

export const RoundButton = connect(mapStateToProps, null)(_RoundButton)
