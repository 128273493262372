import React from 'react';
import { RoundButton } from './RoundButton';

export class NextButton extends React.Component {

  render() {
    return (
      <RoundButton
        className="nextBtn"
        toolTip="Go to next lesson"
        icon="arrow-right"
        { ...this.props }>
        next
      </RoundButton>
    )
  }
}
