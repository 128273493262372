import React from 'react';
import { SectionView } from './SectionView';
import './Lesson.scss';

export class LessonView extends React.Component {

  // props : lesson, onLessonSelected

  render() {
    return (
      <div className="lesson">
        <div key={this.props.lesson.name} className="header">
          <div className="type"> Type </div>
          <div className="section"> Sect. </div>
          <div className="name">{this.props.lesson.name}</div>
          <div className="facts"> Facts </div>
        </div>
        {
          this.props.lesson.sections.map((section) => <SectionView key={`section-view-${section.id}`} section={section} onClearSection={this.props.onClearSection} onLessonSelected={this.props.onLessonSelected}/>)
        }
      </div>
    )
  }
}
