import React from 'react';
import { LessonPage } from './LessonPage';

export class LessonList extends React.Component {
  constructor(props) {
    super(props);

    this.pageIndex = 0;
  }

  render() {
    return (
      <LessonPage pageIndex = {this.pageIndex} onLessonSelected = {this.props.onLessonSelected}
                                               onTeacherInstruction={this.props.onTeacherInstruction} />
    )
  }
}

