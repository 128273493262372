import React from 'react';
import { connect } from 'react-redux';
import { Lessons } from '../../Lessons';
import { getCurrentUser } from '../../store/UserReducer';
import { ProgressLesson } from './ProgressLesson';
import { Legend } from '../Legend';

import './ProgressReport.scss';

class _ProgressReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  printWindow = () => {
    const currentTitle = document.title;
    document.title = "Progress Report";
    window.print();
    document.title = currentTitle;
  }

  render() {
    return (
      <div className="progressReportContainer">
        <div className="report">
          <div className="columnNames">
            <div className="medium-large">Type</div>
            <div className="small">Sect</div>
            <div className="large">Lesson Description</div>
            <div className="medium">Facts</div>
            <div className="medium">Date</div>
            <div className="small">Correct</div>
            <div className="small">Score</div>
            <div className="medium">Time</div>
          </div>
          {
            Lessons.lessons.map((lesson) => <ProgressLesson lesson={lesson} key={`progress-lesson-${ lesson.name }`}/>)
          }
        </div>
        <div className="info">
          <div className="header"> PROGRESS REPORT FOR: </div>
          <div className="headerName"> {this.props.currentUser ? this.props.currentUser : 'Demo'} </div>
          <Legend />
          <button className="printBtn" onClick={ () => this.printWindow() }>print</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentUser: getCurrentUser(state)
});

export const ProgressReport = connect(mapStateToProps, null)(_ProgressReport)
