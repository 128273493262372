import Progress from './Progress'

//const uri = "onlinetimesalivebackend-prod.us-east-2.elasticbeanstalk.com/api/api"
const uri = "/api/api"
//const uri = "https://localhost:44317/api/api"
//const uri = "http://localhost:63230/api"

export default class Api {

  static checkSubscription(teacherId) {
    //TODO: See if email address is active:
      // POST to
      // { "plan": "online-times-alive", "email": "whatevertheemailis"}
      // Content-Type: application/json
    let status;
    const url = "https://www.citycreek.com/wp-json/citycreek/v1/memberships/status";

    let request = new XMLHttpRequest();
    request.open("POST", url, false);
    request.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    request.onload = function() {
      if (this.readyState === 4) {
        if (this.status === 200) {
          let resp = request.response;
          status = JSON.parse(resp);
        }
      }
    }
    request.send(JSON.stringify({ "email": `"${teacherId}"`, "plan": "online-times-alive" }));
    return status;
  }

  static loginUser(teacherId, studentName) {

    let user = undefined;
    const url = `${uri}/Students/${teacherId}/${studentName}`;
    console.log("Invoking GET from", url);

    let request = new XMLHttpRequest();
    //request.responseType = "json";
    request.open("GET", url, false);
    request.onload = function() {
      if (this.readyState === 4) {
        if (this.status === 200 || this.status === 201) {
          let resp = request.response;
          user = JSON.parse(resp);
        }
      }
    };
    request.send(null);
    if (user) {
      user.progress = JSON.parse(user.progress);
    }
    else {
      user = {
        userName: studentName,
        studentId: null,
        teacherId: null,
        progress: Progress.getDefault()
      }
    }
    return user;
  }

  static saveProgress(studentId, progress) {
    const json = JSON.stringify(progress);
    const url = `${uri}/Students/Progress/${studentId}`;
    fetch(url, {
      method: 'PATCH',
      headers: {'Content-Type': 'text/plain'},
      body: json
    })
    .then(response => console.log(response))
    .then(function(response) {
      console.log("OK");
    })
    .catch(error => console.error("Failed to update progress.", error));
  }
}
