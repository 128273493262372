import React from 'react';
import { connect } from 'react-redux';
import { LessonIcon } from '../LessonIcon';
import { ProgressIcon } from '../ProgressIcon';
import { getSectionResults } from '../../store/UserReducer';
import './ProgressSection.scss';

class _ProgressSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  formatScore = () => {
    const { results } = this.props;
    if(results.testResults && results.testResults.score !== null)
      return Math.round(results.testResults.score*100) + '%';
    return '';
  }

  formatDate = () => {
    const { results } = this.props;
    if(results.completedOn) {
      const completed =  new Date(results.completedOn);
      return `${completed.getMonth()+1}/${completed.getDate()}/${completed.getFullYear()}`;
    }
    return '';
  }

  formatTime = () => {
    const { results } = this.props;
    if(results.testResults && results.testResults.time)
      return String(Math.floor(results.testResults.time / 60)) + ':' + String(results.testResults.time % 60).padStart(2, '0');
    return '';
  }

  render() {
    const { results } = this.props;

    return (
      <div className="progressSectionContainer">
        <div className="progress"><ProgressIcon status={results.status}/></div>
        <div className="type"><LessonIcon lessonType={this.props.section.type}/></div>
        <div className="section">{this.props.section.id}</div>
        <div className="name"><div className="nameText">{this.props.section.name}</div></div>
        <div className="facts">{this.props.section.facts}</div>
        <div className="completedOn"> { this.formatDate() } </div>
        {
          this.props.section.type === 'test'
          ?
            <React.Fragment>
              <div className="correct"> { results.testResults && results.testResults.correct } </div>
              <div className="score"> { this.formatScore() } </div>
              <div className="time"> { this.formatTime() } </div>
            </React.Fragment>
          :
          <div className="filler"/>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  results: getSectionResults(state, ownProps.section.id)
});

export const ProgressSection = connect(mapStateToProps, null)(_ProgressSection)
