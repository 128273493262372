import React from 'react';

export class ProgressButton extends React.Component {
  render() {
    const { visible, onClick, disabled } = this.props;
    return (
      <div>
        { visible && 
          <button
            className="progressBtn"
            onClick={onClick}
            disabled={disabled}>
            student progress report
          </button>
        }
      </div>
    )
  }
}
