import React from 'react';
import { connect } from 'react-redux';
import { RoundButton } from './RoundButton';
import { getMute, setMute } from '../../store/UiReducer';

class _MuteButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  render() {
    return (
      <RoundButton
        onClick={() => this.props.setMute(!this.props.isMute)} 
        className="muteBtn"
        toolTip="Turn the sound off or on"
        icon={ this.props.isMute ? "volume-down" : "volume-mute" }
        { ...this.props }>
        { this.props.isMute ? "sound on" : "sound off" }
      </RoundButton>
    )
  }
}

const mapStateToProps = (state) => ({
  isMute: getMute(state)
});

const mapDispatchToProps = (dispatch) => ({
  setMute: (mute) => dispatch(setMute(mute))
});

export const MuteButton = connect(mapStateToProps, mapDispatchToProps)(_MuteButton)
