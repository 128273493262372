import React from 'react';
import './TestResult.scss';

/*
  props:
    correct
    score
    time
*/
export const TestResult = (props) => {
  //console.log("TestResult props", props);
  return (
    <div className="testResultContainer">
      <div className="resultRow">
        <div>
          <div className="header correct"> CORRECT </div>
          <div className="result"> { props.correct }</div>
        </div>
        <div className="resultColumn">
          <div className="header score"> SCORE </div>
          <div className="result"> { Math.round(props.score*100) }% </div>
        </div>
      </div>
      <div className="resultRow">
        <div className="resultColumn">
          <div className="header time"> TIME </div>
          <div className="result"> { String(Math.floor(props.time / 60)) + ':' + String(props.time % 60).padStart(2, '0') }</div>
        </div>
      </div>
    </div>
  )
}
