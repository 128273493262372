import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { reducer as uiReducer } from './UiReducer';
import { reducer as lessonReducer } from './LessonReducer';
import { reducer as userReducer } from './UserReducer';

import thunk from 'redux-thunk';

const rootReducer = combineReducers({
  ...uiReducer,
  ...lessonReducer,
  ...userReducer
});

export function Store() {
  const debug = true;

  if (debug) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
  }

  return createStore(rootReducer, applyMiddleware(thunk));
}
