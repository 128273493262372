import React from 'react'

/* props
    image: an image file
    isCorrect: true or false
    onSelected: function callback when clicked
*/

export class Slide extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      visible: true,
    }
  }

  render() {
    const imageFile = "assets/image/" + this.props.image;
    return (
      <div className="slide">
        { this.state.visible &&
          <img src={imageFile} alt="TODO" onClick={() => {
            this.props.onSelected(this.props.isCorrect);
            this.setState({ visible: this.props.isCorrect})
          }}/>
        }
      </div>
    )
  }
}
