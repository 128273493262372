import React from 'react';
import { connect } from 'react-redux';
import { RoundButton } from './RoundButton';
import { getShowHelp, setShowHelp } from '../../store/UiReducer';


export class _HelpButton extends React.Component {
  
  constructor(props) {
    super(props);

    this.timeout = null;
  }
  
  onClick = () => {
    if(!this.props.showHelp) {
      console.log("SHOW HELP");
      this.props.setShowHelp(true);
      this.timeout = setTimeout(() => {
        this.props.setShowHelp(false);
        this.timeout = null;
      }, 10000);
    } else {
      console.log("HIDE HELP");
      if(this.timeout) {
        console.log("cancel timer");
        clearTimeout(this.timeout);
        this.timeout = null;
      }
      this.props.setShowHelp(false);
    }
  }

  render() {
    return (
      <RoundButton
        className="helpBtn"
        icon="question"
        onClick={ () => this.onClick() }
        { ...this.props }>
        help
      </RoundButton>
    )
  }
}

const mapStateToProps = (state) => ({
  showHelp: getShowHelp(state)
});

const mapDispatchToProps = (dispatch) => ({
  setShowHelp: (mute) => dispatch(setShowHelp(mute))
});

export const HelpButton = connect(mapStateToProps, mapDispatchToProps)(_HelpButton)
