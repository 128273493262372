import React from 'react';
import { Slide } from './Slide';
import { AudioTypes, GameAudio } from '../GameAudio';
import { randomNumber } from '../../util';

/*
  props: 
    onComplete: function callback
    slides : [{ image, isCorrect: bool}]
    instructions: text
    audio: audio file
*/

export class PickOne extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      audio: AudioTypes.intro,
      audioKey: randomNumber(0,1000) // without this subsequent queues to wrong answer won't play
    }
  }

  onSlideSelected = (correct) => {
    if(correct)
      this.setState({audio: AudioTypes.correct, audioKey: randomNumber(0,1000)});
    else 
      this.setState({audio: AudioTypes.wrong, audioKey: randomNumber(0,1000)});
  }

  onAudioComplete() {
    if(this.state.audio === AudioTypes.correct)
      this.props.onComplete();
  }

  render() {   
    return (
      <div className="pickOne">
        <div className="pictures">
          {
            this.props.slides.map((slide, index) => <Slide key={`slide${index}`} {...slide} onSelected={this.onSlideSelected}/>)
          }
        </div>
        <GameAudio 
          key={`spAudio-${this.state.audioKey}`} 
          src={[this.props.audio]} 
          type={this.state.audio} 
          onAudioComplete={() => this.onAudioComplete()}/>
      </div>
    )
  }
}
