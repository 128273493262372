import React from 'react';
import { connect } from 'react-redux';
import { getPlaying, getMute } from '../store/UiReducer';

import './IntroMovie.scss'

export class _IntroMovie extends React.Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
  }
  componentDidMount() {
    this.playVideo();
  }

  componentDidUpdate(prevProps) {
    if (this.props.isPlaying !== prevProps.isPlaying) {
      if (this.props.isPlaying)
        this.playVideo();
      else
        this.pauseVideo();
    }
    this.vidRef.current.isMute = this.props.isMute;
  }

/*
  render() {
    console.log("Intro Movie State", this.state);
    return <div> { this.props.myisPlaying ? 'Movie playing' : 'Movie Paused' } </div>
  }
*/

  onPlayClicked() {
    this.playVideo();
  }

  onVideoPlaying() {
    console.log("Video is playing");
    document.getElementById("playorpause").style.display = "none";
  }

  playVideo() {
    console.log("IntroMovie: playVideo() called");
    this.vidRef.current.play();
  }
  pauseVideo() {
    console.log("IntroMovie: pauseVideo() called:");
    this.vidRef.current.pause();
  }

  render() {
    const videoFile = "assets/video/WelcomeTimesAlive.mp4";
    return (
      <div>
        <video ref={this.vidRef} width="980" height="524" muted={this.props.isMute} controls onEnded={() => this.props.onComplete()}  onPlaying={this.onVideoPlaying}>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div id="playorpause" className="playpause" onClick={() => this.playVideo()}/>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isPlaying: getPlaying(state),
  isMute: getMute(state)
});

const mapDispatchToProps = (dispatch) => ({
});

export const IntroMovie = connect(mapStateToProps, mapDispatchToProps)(_IntroMovie)
