import { pageTypes } from '../data'

const initialState = {
  isPlaying: true,
  isMute: false,
  pageType: pageTypes.introMovie,
  showHelp: false
}

const actionTypes = {
  SET_PLAYING: 'SET_PLAYING',
  SET_MUTE: "SET_MUTE",
  SET_PAGE_TYPE: "SET_PAGE_TYPE",
  SET_HELP: "SET_HELP"
}

// action creators
export const setPlaying = (playing) => {
  return {
    type: actionTypes.SET_PLAYING,
    payload: playing
  }
}
export const setMute = (mute) => {
  return {
    type: actionTypes.SET_MUTE,
    payload: mute
  }
}
export const setPageType = pageType => {
  return {
    type: actionTypes.SET_PAGE_TYPE,
    payload: pageType
  }
}
export const setShowHelp = help => {
  return {
    type: actionTypes.SET_HELP,
    payload: help
  }
}

// the reducer
const uiReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_PLAYING:
      return {
        ...state,
        isPlaying: action.payload
      }
    case actionTypes.SET_MUTE:
      return {
        ...state,
        isMute: action.payload
      }
    case actionTypes.SET_PAGE_TYPE:
      return {
        ...state,
        pageType: action.payload
      }
    case actionTypes.SET_HELP:
      return {
        ...state,
        showHelp: action.payload
      }
    default:
      return state;
  }
}

const getUiReducer = state => state.UI;

export const getPlaying = state => getUiReducer(state).isPlaying;
export const getMute = state => getUiReducer(state).isMute;
export const getPageType = state => getUiReducer(state).pageType;
export const getShowHelp = state => getUiReducer(state).showHelp;
export const reducer = { UI: uiReducer }
