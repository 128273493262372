import React from 'react';
import { AudioPlayerRandom, AudioPlayerQueue } from './AudioPlayer';

/* props
  src: [] of audio files
  type: intro | wrong | correct
  onAudioComplete: fn()
*/

export const AudioTypes = {
  intro: 'intro',
  wrong: 'wrong',
  correct: 'correct',
  done: 'done'
}

export const GameAudio = (props) => {
  const correctFeedback = ['excellent.mp3', 'goodjob.mp3', 'thatsright.mp3'];
  const wrongFeedback = ['notquiteright.mp3', 'oopsthinkagain.mp3', 'tryagain.mp3'];
  const doneFeedback = ['finishedgoodjob.mp3'];

  switch(props.type) {
    case AudioTypes.intro :
      return (<AudioPlayerQueue src={props.src} onAudioComplete={props.onAudioComplete}/>);
    case AudioTypes.correct:
      return (<AudioPlayerRandom src={correctFeedback} onAudioComplete={props.onAudioComplete}/>);
    case AudioTypes.wrong:
      return (<AudioPlayerRandom src={wrongFeedback} onAudioComplete={props.onAudioComplete}/>);
    case AudioTypes.done :
      return (<AudioPlayerQueue src={doneFeedback} onAudioComplete={props.onAudioComplete}/>);
    default: return null;
  }
}