import React from 'react';
import { RoundButton } from './RoundButton';

export class LessonListButton extends React.Component {

  render() {
    return (
      <RoundButton
        className="lessonListBtn"
        toolTip="Select a different lesson"
        icon="folder-open"
        { ...this.props }>
        lesson list
      </RoundButton>
    )
  }
}
