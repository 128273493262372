import React from 'react';
import { ButtonPanel } from './ButtonPanel';
import { BackButton, PlayButton, MuteButton, QuitButton,
         PrintTestButton, ProgressButton,
         HelpButton, LessonListButton, ReplayButton, NextButton
} from './buttons';
import {pageDefinitions} from '../../data';

import './ButtonToolbar.scss';

export class ButtonToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  getPageDefinition = () => {
    if(pageDefinitions[this.props.currentPage])
      return pageDefinitions[this.props.currentPage].buttons;

    return {
      back: { enabled: true},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: true, enabled: true},
      progressReport: { visible: true, enabled: true},
      help: { enabled: true},
      lessonList: { enabled: true},
      replay: { enabled: true},
      next: { enabled: true},
    }
  }
  /* props:

  */
  render() {
    //console.log("ButtonToolbar Controller", this.state);
    //console.log("ButtonToolbar Props", this.props);

    const layout = this.getPageDefinition();
    //console.log("ButtonToolbar layout", layout);

    return (
      <div className="buttonToolBar">
        <ButtonPanel className="buttonRow">
          <BackButton
            disabled={!layout.back.enabled}
            onClick={this.props.onBack} />
          <MuteButton
            disabled={!layout.mute.enabled} />
          <PlayButton
            disabled={!layout.play.enabled}/>
          <QuitButton
            disabled={!layout.quit.enabled} />
        </ButtonPanel>
        <ButtonPanel className="buttonColumn">
          <PrintTestButton />
          <ProgressButton
            visible={layout.progressReport.visible}
            disabled={!layout.progressReport.enabled}
            onClick={this.props.onProgressReport }/>
        </ButtonPanel>
        <ButtonPanel className="buttonRow">
          <HelpButton
            disabled={!layout.help.enabled} />
          <LessonListButton
            disabled={!layout.lessonList.enabled}
            onClick={this.props.onLessonList} />
          <ReplayButton
            disabled={!layout.replay.enabled}
            onClick={this.props.onReplay} />
          <NextButton
            disabled={!layout.next.enabled}
            onClick={this.props.onNext} />
        </ButtonPanel>
      </div>
    )
  }

}

