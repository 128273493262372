import React from 'react';
import { LessonIcon } from './LessonIcon';
import { ProgressIcon } from './ProgressIcon';
import { combineClasses } from '../util';
import './Legend.scss';

/*
  props: className
*/

export const Legend = (props) => {

  return (
    <div className={combineClasses("legendContainer", props.className)}>
      <div> <LessonIcon lessonType="movie"/> Movie </div>
      <div> <LessonIcon lessonType="song"/> Song </div>
      <div> <LessonIcon lessonType="guessinggame"/> Quiz </div>
      <div> <LessonIcon lessonType="test"/> Test </div>
      <div> <ProgressIcon status="viewed"/> Viewed </div>
      <div> <ProgressIcon status="completed"/> Completed </div>
    </div>
  )
}