import React from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux'
import { Lessons } from '../../Lessons';
import { LessonView } from './LessonView';
import { LessonColumn } from './LessonColumn';
import { clearTestResults } from '../../store/UserReducer'
import { Legend } from '../Legend';
import "./Lesson.scss";

const modalDialogStyles = {
  content : {
    width: '300px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '1px solid #0d2d70',
    textSize: "12pt",
    fontWeight: "600"
  }
};

const layout = {
  pages: [
    {
      buttonText: "12 - 18",
      columns: [
        {lessonFirst: 0, lessonLast: 4},
        {lessonFirst: 5, lessonLast: 8},
        {lessonFirst: 9, lessonLast: 10},
      ]
    },
    {
      buttonText: "1 - 11",
      columns: [
        {lessonFirst: 11, lessonLast: 13},
        {lessonFirst: 14, lessonLast: 16},
        {lessonFirst: 17, lessonLast: 17},
      ]
    }
  ]
};

export class _LessonPage extends React.Component {
  constructor(props) {
    super(props);
    console.log("LessonPage constructed:", props);

    this.state = {
      password: '',
      showClearConfirmModal: false,
      showClearPasswordModal: false
    };

    this.pageIndex = this.props.pageIndex;

    this.onLessonSelected = this.onLessonSelected.bind(this);
    this.onChangePage = this.onChangePage.bind(this);
  }

  closePasswordDialog = () => {
    this.setState({...this.state, showClearPasswordModal: false})
  }

  onChangePage() {
    this.pageIndex = this.pageIndex ? 0 : 1;
    this.setState({pageIndex: this.pageIndex});
  }

  onLessonSelected(lessonId) {
    this.props.onLessonSelected(lessonId);
  }

  onClearRequested = sectionId => {
    this.setState({
      ...this.state,
      sectionToClear: sectionId,
      showClearConfirmModal: true
    })
  }

  clearSection = () => {
    this.props.clearTestResults(this.state.sectionToClear);
  }

  onYes = () => {
    console.log("YES button clicked.")
    this.setState({
      ...this.state,
      showClearConfirmModal: false,
      showClearPasswordModal: true
    })
  }

  onNo = () => {
    this.setState({
      ...this.state,
      showClearConfirmModal: false
    })
  }

  onPasswordChange = (event) => {
    this.setState({password: event.target.value});
  }

  onSubmit = (event) => {
    console.log("onSubmit, event", event);
    const pw = "clearresults" + this.state.sectionToClear;
    if (this.state.password !== pw) {
      event.preventDefault();
      event.stopPropagation();
      document.getElementById("password-prompt").style.visibility="visible";
      document.getElementById("password-input").focus();
    }
    else {
      event.preventDefault();
      event.stopPropagation();
      this.clearSection();
      this.setState({
        ...this.state,
        showClearPasswordModal: false
      })
    }
  }

  renderArrowButton() {
    if (this.pageIndex) {
      return (
      <div className="prevPageArrow">
        <svg width="114" height="44" onClick={this.onChangePage}>
          <path d="M 0 22 L 22 0 L 22 7 L 114 7 L 114 36 L 22 36 L 22 44 L 0 22 Z"
                stroke="black" fill="red" />
          <text x="70" y="30" fill="white" textAnchor="middle">{this.page.buttonText}</text>
        </svg>
      </div>
      );
    }
    else {
      return (
        <div className="nextPageArrow">
        <svg width="114" height="44" onClick={this.onChangePage}>
          <path d="M 114 22 L 92 0 L 92 7 L 0 7 L 0 36 L 92 36 L 92 44 L 114 22 Z"
                stroke="black" fill="red" />
          <text x="50" y="30" fill="white" textAnchor="middle">{this.page.buttonText}</text>
        </svg>
      </div>
      );
    }
  }

  render() {
    this.page = layout.pages[this.pageIndex];
    return (
      <div className="lessonContainerContainer">
        <div className="lessonContainer">
          {
            this.page.columns.map((column, index) => <LessonColumn key={`lesson-column-${column.lessonFirst+index}`}>{Lessons.lessons.slice(column.lessonFirst, column.lessonLast + 1).map((lesson) => <LessonView key={`lesson-view-${lesson.name}`}lesson={lesson} onClearSection={this.onClearRequested} onLessonSelected={this.onLessonSelected} /> )}</LessonColumn>)
          }
        </div>
        <Legend className="lessonLegend" />
        {this.renderArrowButton()}
        <div className="moreLessons">More<br/>Lessons</div>
        <button className="directionsButton" onClick={this.props.onTeacherInstruction}>TEACHER'S DIRECTIONS</button>
        <Modal
          style = {modalDialogStyles}
          isOpen = {this.state.showClearConfirmModal}
        >
          <div className="dialogText">
            Do you want to erase the results of this test? This action can not be undone.
            <br/>
          </div>
          <div className="buttonRow">
            <button className="yesButton" onClick={this.onYes}>
              Yes
            </button>
            <button className="noButton" onClick={this.onNo}>
              No
            </button>
          </div>
        </Modal>
        <Modal
          style={modalDialogStyles}
          isOpen={this.state.showClearPasswordModal}
        >
          <div>
            <span className="closeX" onClick={this.closePasswordDialog}>&times;</span>
            <form>
              <label className="passwordLabel">Please enter Password.</label><br/>
              <input
                id="password-input"
                className="passwordInput"
                type="text"
                autoFocus
                autoComplete="off"
                value={this.state.password}
                onChange={this.onPasswordChange} /><br/><br/>
              <div className="passwordButtonRow">
                <div className="passwordPrompt" id="password-prompt">
                  The password can be found in the Teacher’s Directions on the Lesson List Page.
                </div>
                <input
                  className="passwordEnterButton"
                  type="submit"
                  value="ENTER"
                  onClick={this.onSubmit} />
              </div>
            </form>
          </div>
        </Modal>
      </div>
    );
  }
}


const mapDispatchToProps = (dispatch) => ({
  clearTestResults: (sectionId) => dispatch(clearTestResults(sectionId)),
});

export const LessonPage = connect(null, mapDispatchToProps)(_LessonPage)
