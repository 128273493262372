import { lessons } from './data'


export class Lessons {
  static lessons = lessons;

  static getLessonSection(sectionId) {
    console.log("Looking for lesson section " + sectionId);
    let rv = null;
    const lesson = lessons.find((lesson) => {
      return lesson.sections.find(section => section.id === sectionId)
    });

    if (lesson)
    {
      console.log("Looking for section " + sectionId + " in lesson " + lesson.name);
      let section = lesson.sections.find(section => section.id === sectionId);
      console.log(section);
      if (section)
        rv = section;
    }

    return rv;
  }

  static nextLessonPage(id, pageIndex) {
    console.log("on entry, id", id);
    console.log("on entry, pageNdx", pageIndex);
    if (!id)
      return { id: lessons[0].id, pageIndex: 0 };

    let lessonNdx = lessons.findIndex(lesson => {
      return lesson.sections.find(section => section.id === id)
    });
    if (lessonNdx < 0)
      return null;

    let lesson = lessons[lessonNdx];
    console.log(`lessons[${lessonNdx}]`, lesson);
    let sectionNdx = lesson.sections.findIndex(section => section.id === id);
    if (sectionNdx < 0)
      return null;

    let section = lesson.sections[sectionNdx];
    console.log("section", section);
    console.log("pageNdx", pageIndex);
    if (pageIndex < section.pages.length - 1)
      return { id: id, pageIndex: ++pageIndex };

    pageIndex = 0;
    if (sectionNdx < lesson.sections.length - 1) {
      ++sectionNdx;
      return { id: lesson.sections[sectionNdx].id, pageIndex: pageIndex}
    }

    sectionNdx = 0;
    if (lessonNdx < lessons.length - 1) {
      lesson = lessons[++lessonNdx];
      return { id: lesson.sections[sectionNdx].id, pageIndex: pageIndex }
    }

    return null;
  }

  static previousLessonPage(id, pageIndex) {
    console.log("on entry, id", id);
    console.log("on entry, pageNdx", pageIndex);
    if (!id)
      return null;

    let lessonNdx = lessons.findIndex(lesson => {
      return lesson.sections.find(section => section.id === id)
    });
    if (lessonNdx < 0)
      return null;

    let lesson = lessons[lessonNdx];
    let sectionNdx = lesson.sections.findIndex(section => section.id === id);
    if (sectionNdx < 0)
      return null;

    if (pageIndex > 0)
      return { id: id, pageIndex: --pageIndex };

    let section = lesson.sections[sectionNdx];
    if (sectionNdx > 0) {
      section = lesson.sections[--sectionNdx];
      pageIndex = section.pages.length - 1;
      return { id: section.id, pageIndex: pageIndex };
    }

    if (lessonNdx === 0)
      return null;

    lesson = lessons[--lessonNdx]
    sectionNdx = lesson.sections.length - 1;
    section = lesson.sections[sectionNdx];
    pageIndex = section.pages.length - 1;
    return { id: section.id, pageIndex: pageIndex };
  }

  static isFirstPage(id, pageIndex) {
    return this.previousLessonPage(id, pageIndex) === null;
  }

  static isLastPage(id, pageIndex) {
    return this.nextLessonPage(id, pageIndex) == null;
  }

  static isLastLessonPage(id, pageIndex)
  {
    const section = Lessons.getLessonSection(id);

    return pageIndex === section.pages.length - 1;
  }
}
