import React from 'react'
import { connect } from 'react-redux';
import { setPlaying, getPlaying, getMute } from '../store/UiReducer';

export class _Movie extends React.Component {
  constructor(props) {
    super(props);
    this.vidRef = React.createRef();
  }
  componentDidMount() {
    console.log("Movie component did mount")
    this.playVideo();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("componentDidUpdate", prevProps, prevState);
    if (this.props.isPlaying)
      this.playVideo();
    else
      this.pauseVideo();
    this.vidRef.current.muted = this.props.isMute;
  }

  playVideo() {
    console.log("Movie: playVideo() called");
    //if (this.vidRef.current)
      this.vidRef.current.play();
  }
  pauseVideo() {
    console.log("Movie: pauseVideo() called:");
    this.vidRef.current.pause();
  }

  render() {
    const page = this.props.page;
    const videoFile = "assets/video/" + page.video;
    console.log(`should be playing ${videoFile}`)
    return (
      <div>
        <video key={videoFile} ref={this.vidRef} width="980" height="524" muted={this.props.isMute} controls onEnded={() => this.props.onLessonComplete()}>
          <source src={videoFile} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  isPlaying: getPlaying(state),
  isMute: getMute(state)
});

const mapDispatchToProps = (dispatch) => ({
  setPlaying: (playing) => dispatch(setPlaying(playing))
});

export const Movie = connect(mapStateToProps, mapDispatchToProps)(_Movie)
