import React from 'react';
import { connect } from 'react-redux';
import { RoundButton } from './RoundButton';
import { setPlaying, getPlaying } from '../../store/UiReducer';

class _PlayButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
     
    }
  }

  /*
    playing 
    paused
  */

  render() {
    return (
      <RoundButton
        onClick={() => this.props.mysetPlaying(!this.props.myisPlaying)} 
        className="playBtn"
        toolTip="Stop or start the lesson"
        icon={ this.props.myisPlaying ? "pause" : "play" }
        { ...this.props }>
        { this.props.myisPlaying ? 'pause ' : 'play' }
      </RoundButton>
    )
  }
}

const mapStateToProps = (state) => ({
  myisPlaying: getPlaying(state)
});

const mapDispatchToProps = (dispatch) => ({
  mysetPlaying: (playing) => dispatch(setPlaying(playing))
});

export const PlayButton = connect(mapStateToProps, mapDispatchToProps)(_PlayButton)