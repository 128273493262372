import React from 'react';
import { Equation } from '../Equation';
import { AudioTypes, GameAudio } from '../GameAudio';
import { randomNumber } from '../../util';

/*
  props:
    onComplete: function callback
    image : image to show
    audio: audio file
    x & y : the math question, ie 3 x 4
*/

export class GuessWithPicture extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isValid: false,
      showInvalid: false,
      audio: AudioTypes.intro,
      audioKey: randomNumber(0,1000) // without this subsequent queues to wrong answer won't play
    }
  }

  onCheck = () => {
    if(this.state.isValid)
      this.setState({audio: AudioTypes.correct, audioKey: randomNumber(0,1000)});
    else
      this.setState({showInvalid: true, audio: AudioTypes.wrong, audioKey: randomNumber(0,1000)});
  }

  onAudioComplete() {
    if(this.state.audio === AudioTypes.correct)
      this.props.onComplete();
  }

  render() {
    const imageFile = "assets/image/" + this.props.image;

    return (
      <div className="guessWithPicture">
        <div className="instructions">
          <label>Do you remember the pictures in the story?</label>
          <label>Enter the fact and the answer, then click CHECK.</label>
        </div>
        <div className="picture">
          <img src={imageFile} alt="TODO" />
        </div>
        <div className="problem">
          <Equation
            className="guessEquation"
            direction="row"
            equation={
              {
                x: { value: this.props.x, input: true },
                y: { value: this.props.y, input: true }
              }
            }
            reportResult={(result) => this.setState({isValid: result.valid})}
            showInvalid={this.state.showInvalid}
            text="Fact:" />
          <button className="checkBtn" onClick={this.onCheck}>check</button>
        </div>
        <GameAudio
          key={`ggAudio-${this.state.audioKey}`}
          src={[this.props.audio]}
          type={this.state.audio}
          onAudioComplete={() => this.onAudioComplete()}/>
      </div>
    )
  }
}
