import React from 'react';
import './TeacherDirections.scss'

export class TeacherDirections extends React.Component {

  printWindow = () => {
    const currentTitle = document.title;
    document.title = "Teacher's Directions";
    window.print();
    document.title = currentTitle;
  }

render() {
    return (
      <div className="teacherDirectionsContainer">
      <div className="teacherDirections">
        <div className="column">
          <p>Program Objective</p>
          <ol>
            <li>Student will commit the multiplication facts to long-term memory with rapid recall.</li>
            <li>Student will develop a positive attitude toward math prompted by the success and entertainment value of the program.</li>
            <li>Student will have the tools to succeed in math because they will have the nuts and bolts, basic facts committed to memory.</li>
            <li>Student will apply these tools to all future mathematical operations including division, fractions, algebra, and onward.</li>
          </ol>
          <p>How to Use This Program</p>
          <ol>
            <li>After the welcome screen the student will login with the teacher's username or email address. When signing up, pick an easy username to make it simple for students to login.</li>
            <li>When the student enters their unique name under Student’s Name, their record is created.</li>
            <li>After login, the student will be directed to a personalized lesson list which will show where the student left off and which lessons are completed. The student must always login with the exact same Student Name to keep track and record progress and scores.</li>
            <li>The student will resume lessons by clicking on the first lesson with an empty circle in the left column of the Lesson List.</li>
            <li>If a lesson has been viewed but not completed, the circle will be half filled.</li>
            <li>The column labeled "FACTS" on the Lesson List shows which facts will be addressed in each lesson.</li>
            <li>The student should follow the lesson order to ensure that the material on the tests has been covered.</li>
            <li>If it has been determined through the pretest, Nuts and Bolts Test, that the student already knows the facts addressed in the lesson, the student may skip those lessons, but should still adhere to the prescribed lesson order.</li>
          </ol>
          <p>How to Use the Progress Report</p>
          <ol>
            <li>The Progress Report is a running record of the lessons the student has completed. It will show:</li>
            <ol type='a'>
              <li>Lesson completion</li>
              <li>Date of completion</li>
            </ol>
          </ol>
        </div>
        <div className="column">
          <ol start="2">
            <ol type='a' start="3">
              <li>Test scores including number correct and percent score</li>
              <li>Time elapsed during the test administration</li>
            </ol>
            <li>Several of the tests are taken twice during the program. Compare the student's scores on the Progress Report with the previously taken test. The second score should have a faster time or higher score than the first. If this is not the case, have the student repeat the lesson pertaining to the test. The tests that are taken twice are: One Two Buckle My Shoe, Man Alive Try the Fives, and Nines in a Line.</li>
            <li>The Progress Report may be printed at the end of each lesson or at any time during the program.</li>
          </ol>
          <p>Clear Test Function</p>
          <ol>
            <li>If you would like a test re-taken, you may click on the Clear button that appears next to the completed test on the Lesson List.</li>
            <li>To eliminate the student's clearing their own test scores, the program will ask for a password to re-set the test and erase the previous results. The password is clearresults<i>xx</i> where <i>xx</i> is the lesson number i.e., 1b.</li>
          </ol>
          <p>Accessing Student Records</p>
          <ul className="noBullets">
            <li>You may enter the Times Alive Membership Area — onlinetimesalive.com from any computer. Login with your email address or username and the student’s exact name to view or print the individual’s progress report.</li>
          </ul>
          <p>Online Times Alive Memberships</p>
          <ul className="noBullets">
            <li>Monthly subscriptions run until cancelled. Subscribers can cancel, renew, or update their subscription at any time by visiting <a href="https://www.citycreek.com/my-account" rel="noopener noreferrer" target="_blank">citycreek.com/my-account</a>. Each teacher may use their account for up to 30 students. It is a violation of the terms of agreement to share the account among teachers or entire schools or districts.</li>
          </ul>
        </div>
    </div>
    <button className="printButton" onClick={() => this.printWindow()}>PRINT</button>
    </div>
    );
  }
}
