import React from 'react';
import { connect } from 'react-redux'
import { getCurrentLesson, getLessonPage, advanceLesson } from '../store/LessonReducer';
import { markSectionViewed, markSectionComplete } from '../store/UserReducer'
import { Movie } from './Movie';
import { Test } from './Test';
import { GuessingGame } from './GuessingGame';
import { SlidingPicture } from './SlidingPicture';
import { Lessons } from '../Lessons';

class _Lesson extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  onLessonPageComplete = () => {
    console.log("onLessonComplete()");
    console.log("props", this.props);
    if (this.props.currentLesson.pages[this.props.lessonPage].recordProgress)
      this.props.markSectionCompleted(this.props.currentLesson.id);
    if (this.props.currentLesson.pages[this.props.lessonPage].autoAdvance)
      this.props.advanceLesson(this.props.currentLesson.id, this.props.lessonPage);
  }

  render() {
    console.log("Lesson Props", this.props);
    console.log("Last Lesson:", Lessons.isLastPage(this.props.currentLesson.id, this.props.lessonPage));
    console.log("First Lesson:", Lessons.isFirstPage(this.props.currentLesson.id, this.props.lessonPage));
    console.log("Lesson Page", this.props.currentLesson.pages[this.props.lessonPage]);
    const page = this.props.currentLesson.pages[this.props.lessonPage];
    if(page === undefined) {
      return null;
    }

    this.props.markSectionViewed(this.props.currentLesson.id);

    switch (page.type) {
      case "movie":
        return (<Movie page={page} onLessonComplete={this.onLessonPageComplete}/>);
      case "test":
        return (<Test sectionId={this.props.currentLesson.id} page={page} onLessonComplete={this.onLessonPageComplete}/>);
      case "guessinggame":
        return (<GuessingGame page={page} onLessonComplete={this.onLessonPageComplete}/>);
      case "slidingpicture":
        return (<SlidingPicture page={page} onLessonComplete={this.onLessonPageComplete}/>);
      default:
        return (<div>Error!</div>);
    }
  }
}

const mapStateToProps = (state) => ({
  currentLesson: getCurrentLesson(state),
  lessonPage: getLessonPage(state)
});

const mapDispatchToProps = (dispatch) => ({
  advanceLesson: (lessonid, pageid) => dispatch(advanceLesson(lessonid, pageid)),
  markSectionViewed: (sectionid) => dispatch(markSectionViewed(sectionid)),
  markSectionCompleted: (sectionid) => dispatch(markSectionComplete(sectionid))
});

export const Lesson = connect(mapStateToProps, mapDispatchToProps)(_Lesson)
