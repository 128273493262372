import React from 'react';
import { ProgressSection } from './ProgressSection';
import './ProgressLesson.scss';
/*
  props: lesson
*/
export const ProgressLesson = (props) => {
  
    return (
      <div className="progressLessonContainer">
        <div className="header"> { props.lesson.name } </div>
        {
          props.lesson.sections.map((section) => <ProgressSection section={section} key={`progress-section-${ section.id }`}/> )
        }
        
      </div>
    )
  
}
