import React from 'react';
import './Lesson.scss';

export class LessonColumn extends React.Component {

  // props : just renders children

  render() {
    return (
      <div className="lessonColumn">
        { this.props.children }
      </div>
    )
  }
}