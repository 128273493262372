import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { combineClasses } from '../util';

/*
  props:
    status: string
    className: string
*/

export const ProgressIcon = (props) => {

  const getIcon = () => {
    switch (props.status) {
      case "viewed":
        return 'adjust';
      case "completed":
        return ["fas", "check-circle"];
      default:
        return ["far", "circle"];
    }
  }

  return (
    <div className={combineClasses("progressIconContainer", props.className)}>
      <FontAwesomeIcon 
        icon={getIcon()} 
        className="icon" />
    </div>
  )
}