import React from 'react';
import { connect } from 'react-redux'
import { LessonIcon } from '../LessonIcon';
import { ProgressIcon } from '../ProgressIcon';
import { getSectionStatus } from '../../store/UserReducer'
import "./Lesson.scss";

export class _SectionView extends React.Component {

  // props : section, onLessonSelected

  onClear(sectionId) {
    console.log("SectionView onClear", sectionId);
    this.props.onClearSection(sectionId);
    this.setState({...this.state});
  }

  render() {
    if (this.props.section.type === "test" && this.props.status === "completed") {
      return (
        <div className="sectionContainerTestComplete">
          <div className="clearButtonArea"><button className="clearButton" onClick={() => this.onClear(`${this.props.section.id}`)}>Clear</button></div>
          <div className="section">{this.props.section.id}</div>
          <div className="name">{this.props.section.name}</div>
          <div className="facts">{this.props.section.facts}</div>
        </div>
      )
    }
    return (
      <div className="sectionContainerContainer">
      <div className="sectionContainer"
        onClick={() => this.props.onLessonSelected(`${this.props.section.id}`)}
      >
        <div className="progress"><ProgressIcon status={this.props.status}/></div>
        <div className="type"><LessonIcon lessonType={this.props.section.type}/></div>
        <div className="section">{this.props.section.id}</div>
        <div className="name">{this.props.section.name}</div>
        <div className="facts">{this.props.section.facts}</div>
      </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  status: getSectionStatus(state, ownProps.section.id)
});

export const SectionView = connect(mapStateToProps, null)(_SectionView)
