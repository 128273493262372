import React from 'react';
import { RoundButton } from './RoundButton';

export class BackButton extends React.Component {

  render() {
    return (
      <RoundButton
        className="backBtn"
        toolTip="Go to previous lesson"
        icon="arrow-left"
        { ...this.props }
        >
        back
      </RoundButton>
    )
  }
}
