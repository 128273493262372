import React from 'react';
import { connect } from 'react-redux';
import { getCurrentLesson, getLessonPage } from '../store/LessonReducer';
import { getCurrentUser } from '../store/UserReducer';
import { getPageType } from '../store/UiReducer';
import { pageTypes } from '../data';
import "./Header.scss";

class _Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

    }
  }

  renderHeader() {
    switch (this.props.pageType) {
      case pageTypes.introMovie:
        return "Welcome to Times Alive!";
      case pageTypes.login:
        return "Version 2.01";
      case pageTypes.lessonList:
        return "Times Alive Lesson List";
      case pageTypes.lesson:
        const lesson = this.props.currentLesson;
        console.log("lesson", lesson);
        return <span>
          <span className="lesson-title">Lesson</span>
          <span className="lesson-section">{lesson.id}</span>
          <span className="lesson-name">{lesson.name}</span>
        </span>
      case pageTypes.progressReport:
        return "Student Progress Report";
      case pageTypes.teacherDirections:
        return "Times Alive Program Directions";
      default:
        return ''
    }
  }

  render() {
    console.log("Header State", this.props)
    return (
      <div className="headerContainer">
        <div className="leftSide">
          <img className="printHeader" src="/assets/image/TimesAliveLogoGreenPrint.png" alt="Times Alive" />
          { this.props.pageType === pageTypes.progressReport &&
            <div className="studentName">
              STUDENT: { this.props.currentUser ? this.props.currentUser : 'Demo' }
            </div>
          }
          <div className="Times-Alive-lesson-indicator">
            {this.renderHeader()}
          </div>
        </div>
        <div className="rightSide">
          <div>
            <div>City Creek | Press</div>
            <div>www.citycreek.com</div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentLesson: getCurrentLesson(state),
  lessonPage: getLessonPage(state),
  pageType: getPageType(state),
  currentUser: getCurrentUser(state)
});

export const Header = connect(mapStateToProps, null)(_Header)
