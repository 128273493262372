import { Lessons } from '../Lessons'


const initialState = {
  currentLesson: '1a',
  lessonPage: 0
}

const actionTypes = {
  SET_CURRENT_LESSON: "SET_CURRENT_LESSON",
  SET_CURRENT_LESSON_PAGE: "SET_CURRENT_LESSON_PAGE",
  SET_CURRENT_LESSON_AND_PAGE: "SET_CURRENT_LESSON_AND_PAGE",
}

export const advanceLesson = (lessonid, pageid) => {
  console.log(`advancing lesson from ${lessonid}, ${pageid}`);
  const newLesson = Lessons.nextLessonPage(lessonid, pageid)
  console.log("newLesson", newLesson);
  // need some error handling here
  return function(dispatch) {
    dispatch(setCurrentLesson(newLesson.id));
    dispatch(setLessonPage(newLesson.pageIndex));
  }
}

export const retreatLesson = (lessonid, pageid) => {
  console.log("retreating lesson");
  const newLesson = Lessons.previousLessonPage(lessonid, pageid);
  // need some error handling here
  return function(dispatch) {
    dispatch(setCurrentLesson(newLesson.id));
    dispatch(setLessonPage(newLesson.pageIndex));
  }
}

// action creators
export const setCurrentLesson = (lessonId) => {
  console.log(`action creator setCurrentLesson: ${lessonId}`)
  return {
    type: actionTypes.SET_CURRENT_LESSON,
    payload: lessonId
  }
}

export const setCurrentLessonAndPage = (lessonId, pageIndex) => {
  console.log(`action creator setCurrentLessonAndPage: ${lessonId} ${pageIndex}`)
  return {
    type: actionTypes.SET_CURRENT_LESSON_AND_PAGE,
    payload: {lesson: lessonId, page: pageIndex}
  }
}
export const setLessonPage = (lessonPage) => {
  return {
    type: actionTypes.SET_CURRENT_LESSON_PAGE,
    payload: lessonPage
  }
}

// the reducer
const lessonReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.SET_CURRENT_LESSON:
      return {
        ...state,
        currentLesson: action.payload
      }
      case actionTypes.SET_CURRENT_LESSON_PAGE:
        return {
          ...state,
          lessonPage: action.payload
        }
      case actionTypes.SET_CURRENT_LESSON_AND_PAGE:
        return {
          ...state,
          currentLesson: action.payload.lesson,
          lessonPage: action.payload.page
        }
      case actionTypes.SET_CURRENT_USER:
        return {
          ...state,
          username: action.payload
        }
      default:
        return state;
  }
}

const getLessonReducer = state => state.LESSON;

export const getCurrentLesson = state => Lessons.getLessonSection(getLessonReducer(state).currentLesson);
export const getLessonPage = state => getLessonReducer(state).lessonPage;
export const reducer = { LESSON: lessonReducer }
