
export const pageTypes = {
  introMovie: 'introMovie',
  login: 'login',
  lessonList: 'lessonList',
  progressReport: 'progressReport',
  teacherDirections: 'teacherDirections',
  lesson: 'lesson',
}

export const lessonTypes = {
  movie: {
    icon: 'video',
  },
  song: {
    icon: 'music',
  },
  test: {
    icon: ['far', 'file-alt'],
  },
  guessinggame: {
    icon: 'question',
  },
  slidingpicture: {
    icon: 'question',
  },
}

export const pageDefinitions = {
  introMovie: {
    buttons: {
      back: { enabled: false},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: false, enabled: false},
      help: { enabled: true},
      lessonList: { enabled: false},
      replay: { enabled: true},
      next: { enabled: true},
    }
  },
  login: {
    buttons: {
      back: { enabled: true},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: false, enabled: false},
      help: { enabled: true},
      lessonList: { enabled: false},
      replay: { enabled: false},
      next: { enabled: false},
    }
  },
  lessonList: {
    buttons: {
      back: { enabled: false},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: true, enabled: true},
      help: { enabled: true},
      lessonList: { enabled: true},
      replay: { enabled: false},
      next: { enabled: false},
    }
  },
  lesson: {
    buttons: {
      back: { enabled: true},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: true, enabled: true},
      help: { enabled: true},
      lessonList: { enabled: true},
      replay: { enabled: true},
      next: { enabled: true},
    }
  },
  // lessonTest: {
  //   buttons: {
  //     back: { enabled: true},
  //     mute: { enabled: true},
  //     play: { enabled: true},
  //     quit: { enabled: true},
  //     printTest: { visible: true, enabled: true},
  //     progressReport: { visible: true, enabled: true},
  //     help: { enabled: true},
  //     lessonList: { enabled: true},
  //     replay: { enabled: true},
  //     next: { enabled: true},
  //   }
  // },
  progressReport: {
    buttons: {
      back: { enabled: false},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: true, enabled: true},
      help: { enabled: true},
      lessonList: { enabled: true},
      replay: { enabled: false},
      next: { enabled: false},
    }
  },
  teacherDirections: {
    buttons: {
      back: { enabled: true},
      mute: { enabled: true},
      play: { enabled: true},
      quit: { enabled: true},
      printTest: { visible: false, enabled: false},
      progressReport: { visible: true, enabled: true},
      help: { enabled: true},
      lessonList: { enabled: true},
      replay: { enabled: false},
      next: { enabled: false},
    }
  }
}


const pretestInstructions = [
  "This test will be used to show how much you will learn during the Times Alive program.",
  "Don't worry if you don't know the answers.",
  "By the end of the program you will probably know them all.",
  "Enter the answer to the questions as quickly as you can.",
  "If you don't know the answer you can guess, or leave it blank.",
  "When you are ready to start, hit the START button.",
  "When you are done, click the DONE button.",
  "Your test will then be scored and your time will be recorded.",
  "Don't forget to click the DONE button as soon as you are finished."
  ];

const posttestInstructions = [
  "Congratulations! You have completed the Times Alive program. ",
  "Let's see how much you have learned. ",
  "Enter the answer to the questions as quickly as you can. ",
  "When you are ready to start, hit the START button. ",
  "When you are done, click the DONE button. ",
  "Your test will then be scored and your time will be recorded. ",
  "Don't forget to click the DONE button as soon as you are finished."
];

const testInstructions = [
  "Enter the answer to the questions as quickly as you can. ",
  "When you are ready to start, hit the START button. ",
  "When you are done, click the DONE button. ",
  "Your test will then be scored and your time will be recorded. ",
  "Don't forget to click the DONE button as soon as you are finished."
];

export const lessons = [
  {
    "name": "Lesson One",
    "sections": [
      {
        "id": "1a",
        "type": "test",
        "name": "Pre-test Nuts and Bolts",
        "facts": "0-9's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "1a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": pretestInstructions,
            "testAudio": "pretest.mp3",
            "testQuestions": [
              { "x": 2, "y": 1 }, { "x": 3, "y": 2 }, { "x": 4, "y": 4 }, { "x": 3, "y": 6 },
              { "x": 7, "y": 8 }, { "x": 8, "y": 9 }, { "x": 1, "y": 3 }, { "x": 2, "y": 4 },
              { "x": 5, "y": 3 }, { "x": 6, "y": 4 }, { "x": 8, "y": 8 }, { "x": 9, "y": 4 },
              { "x": 1, "y": 1 }, { "x": 2, "y": 1 }, { "x": 5, "y": 4 }, { "x": 6, "y": 6 },
              { "x": 9, "y": 3 }, { "x": 6, "y": 9 }, { "x": 7, "y": 4 }, { "x": 2, "y": 9 },
              { "x": 4, "y": 1 }, { "x": 5, "y": 2 }, { "x": 5, "y": 5 }, { "x": 8, "y": 6 },
              { "x": 9, "y": 7 }, { "x": 5, "y": 7 }, { "x": 9, "y": 1 }, { "x": 1, "y": 5 },
              { "x": 0, "y": 7 }, { "x": 3, "y": 7 }, { "x": 6, "y": 1 }, { "x": 6, "y": 7 },
              { "x": 8, "y": 5 }, { "x": 9, "y": 9 }, { "x": 3, "y": 8 }, { "x": 5, "y": 9 },
              { "x": 4, "y": 3 }, { "x": 8, "y": 1 }, { "x": 7, "y": 2 }, { "x": 3, "y": 0 },
              { "x": 7, "y": 7 }, { "x": 1, "y": 7 }, { "x": 2, "y": 6 }, { "x": 8, "y": 4 },
              { "x": 5, "y": 6 }, { "x": 0, "y": 9 }, { "x": 3, "y": 3 }, { "x": 2, "y": 8 }
            ]
          }
        ]
      },
      {
        "id": "1b",
        "type": "movie",
        "name": "Zero is the King",
        "facts": "0",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": true,
            "progressId": "1b",
            "video": "zeros.mp4"
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": false,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 0, "y": 6 }, { "x": 0, "y": 3 },
              { "x": 0, "y": 4 }, { "x": 0, "y": 1 }
            ]
          }
        ]
      },
      {
        "id": "1c",
        "type": "movie",
        "name": "The Lonely Ones",
        "facts": "1's",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": true,
            "progressId": "1c",
            "video": "ones.mp4"
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": false,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 1, "y": 6 }, { "x": 1, "y": 5 },
              { "x": 1, "y": 1 }, { "x": 1, "y": 7 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Two",
    "sections": [
      {
        "id": "2a",
        "type": "movie",
        "name": "The Three Blind Mice",
        "facts": "3x3",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "2a",
            "video": "3x3story.mp4"
          }
        ]
      },
      {
        "id": "2b",
        "type": "song",
        "name": "A Nice Surprise",
        "facts": "3x3",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "2b",
            "video": "3x3song.mp4"
          }
        ]
      },
      {
        "id": "2c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "3x3",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "2c",
            "gameImage": "3x3.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou3x3.mp3" ],
            "x": 3,
            "y": 3
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Three",
    "sections": [
      {
        "id": "3a",
        "type": "movie",
        "name": "Cheers at Southgate",
        "facts": "3x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "3a",
            "video": "3x4story.mp4"
          }
        ]
      },
      {
        "id": "3b",
        "type": "song",
        "name": "Easy and Clear Cheer",
        "facts": "3x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "3b",
            "video": "3x4song.mp4"
          }
        ]
      },
      {
        "id": "3c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "3x4",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "3c",
            "gameImage": "3x4.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou3x4.mp3" ],
            "x": 3,
            "y": 4
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Four",
    "sections": [
      {
        "id": "4a",
        "type": "movie",
        "name": "The Terrific Twos",
        "facts": "2's",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": true,
            "progressId": "4a",
            "video": "twos.mp4"
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": false,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 2, "y": 6 }, { "x": 2, "y": 4 },
              { "x": 2, "y": 7 }, { "x": 2, "y": 3 }
            ]
          }
        ]
      },
      {
        "id": "4b",
        "type": "test",
        "name": "One Two Buckle My Shoe I",
        "facts": "1's & 2's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "4b",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 1, "y": 1 }, { "x": 1, "y": 2 }, { "x": 3, "y": 1 }, { "x": 6, "y": 1 },
              { "x": 8, "y": 1 }, { "x": 9, "y": 1 }, { "x": 7, "y": 1 }, { "x": 5, "y": 1 },
              { "x": 1, "y": 4 }, { "x": 2, "y": 2 }, { "x": 5, "y": 2 }, { "x": 2, "y": 6 },
              { "x": 2, "y": 9 }, { "x": 8, "y": 2 }, { "x": 7, "y": 2 }, { "x": 2, "y": 4 },
              { "x": 2, "y": 3 }, { "x": 3, "y": 3 }, { "x": 4, "y": 3 }, { "x": 97, "y": 1 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Five",
    "sections": [
      {
        "id": "5a",
        "type": "test",
        "name": "Progress Check I",
        "facts": "Lessons 1-4",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "5a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 3, "y": 3 }, { "x": 7, "y": 0 }, { "x": 2, "y": 3 }, { "x": 4, "y": 3 },
              { "x": 6, "y": 1 }, { "x": 3, "y": 4 }, { "x": 2, "y": 2 }, { "x": 4, "y": 2 },
              { "x": 8, "y": 1 }, { "x": 0, "y": 4 }
            ]
          }
        ]
      },
      {
        "id": "5b",
        "type": "movie",
        "name": "Snowmen Go Camping",
        "facts": "8x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "5b",
            "video": "8x8story.mp4"
          }
        ]
      },
      {
        "id": "5c",
        "type": "song",
        "name": "Snow Falling on Cedars",
        "facts": "8x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "5c",
            "video": "8x8song.mp4"
          }
        ]
      },
      {
        "id": "5d",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "8x8",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "5d",
            "gameImage": "8x8.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou8x8.mp3" ],
            "x": 8,
            "y": 8
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Six",
    "sections": [
      {
        "id": "6a",
        "type": "movie",
        "name": "Keeping America Clean",
        "facts": "7x7",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "6a",
            "video": "7x7story.mp4"
          }
        ]
      },
      {
        "id": "6b",
        "type": "song",
        "name": "Marchin' Hard",
        "facts": "7x7",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "6b",
            "video": "7x7song.mp4"
          }
        ]
      },
      {
        "id": "6c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "7x7",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "6c",
            "gameImage": "7x7.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou7x7.mp3" ],
            "x": 7,
            "y": 7
          }
        ]
      },
      {
        "id": "6d",
        "type": "slidingpicture",
        "name": "Click the Picture I",
        "facts": "3x3, 3x4, 7x7, 8x8",
        "pages": [
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 7x7?",
            "slideAudio": [ "find7x7.mp3", "box7x7.mp3" ],
            "slideHint": "When it's 7x7 and the (7)'s are soldiers who sit in their (4) fort and they make sure America stays clean. The sign looks like a (9).",
            "slides": [
              { "image": "3x4.jpg", "isCorrect": false },
              { "image": "3x3.jpg", "isCorrect": false },
              { "image": "7x7.jpg", "isCorrect": true },
              { "image": "8x8.jpg", "isCorrect": false }
            ],
            "x": 7,
            "y": 7
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 3x4?",
            "slideAudio": [ "find3x4.mp3", "box3x4.mp3" ],
            "slideHint": "When it's 3x4, the kids begin to cheer.  The beginning of the cheer, one - two (12), is the answer to 3x4.",
            "slides": [
              { "image": "3x4.jpg", "isCorrect": true },
              { "image": "3x3.jpg", "isCorrect": false },
              { "image": "7x7.jpg", "isCorrect": false },
              { "image": "8x8.jpg", "isCorrect": false }
            ],
            "x": 3,
            "y": 4
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 8x8?",
            "slideAudio": [ "find8x8.mp3", "box8x8.mp3" ],
            "slideHint": "When it's 8x8, they are two snowmen who are very cold and the sticks (6) are (4) the fire.",
            "slides": [
              { "image": "3x4.jpg", "isCorrect": false },
              { "image": "3x3.jpg", "isCorrect": false },
              { "image": "7x7.jpg", "isCorrect": false },
              { "image": "8x8.jpg", "isCorrect": true }
            ],
            "x": 8,
            "y": 8
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 3x3?",
            "slideAudio": [ "find3x3.mp3", "box3x3.mp3" ],
            "slideHint": "When it's 3x3, there are 3 blind mice with 3 tails each - 9 all together. And blind sounds like nine.",
            "slides": [
              { "image": "3x4.jpg", "isCorrect": false },
              { "image": "3x3.jpg", "isCorrect": true },
              { "image": "7x7.jpg", "isCorrect": false },
              { "image": "8x8.jpg", "isCorrect": false }
            ],
            "x": 3,
            "y": 3
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "progressId": "6d",
            "testQuestions": [
              { "x": 7, "y": 7 }, { "x": 3, "y": 4 },
              { "x": 8, "y": 8 }, { "x": 3, "y": 3 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Seven",
    "sections": [
      {
        "id": "7a",
        "type": "movie",
        "name": "Bart Goes Hang Gliding",
        "facts": "4x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "7a",
            "video": "4x4story.mp4"
          }
        ]
      },
      {
        "id": "7b",
        "type": "song",
        "name": "Fly Fly Away",
        "facts": "4x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "7b",
            "video": "4x4song.mp4"
          }
        ]
      },
      {
        "id": "7c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "4x4",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "7c",
            "gameImage": "4x4.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou4x4.mp3" ],
            "x": 4,
            "y": 4
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Eight",
    "sections": [
      {
        "id": "8a",
        "type": "movie",
        "name": "The Magic Pond",
        "facts": "6x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "8a",
            "video": "6x4story.mp4"
          }
        ]
      },
      {
        "id": "8b",
        "type": "song",
        "name": "Long to Be Soarin'",
        "facts": "6x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "8b",
            "video": "6x4song.mp4"
          }
        ]
      },
      {
        "id": "8c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "6x4",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "8c",
            "gameImage": "6x4.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou6x4.mp3" ],
            "x": 6,
            "y": 4
          }
        ]
      },
      {
        "id": "8d",
        "type": "test",
        "name": "One Two Buckle My Shoe II",
        "facts": "1's & 2's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "8d",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 1, "y": 1 }, { "x": 1, "y": 2 }, { "x": 3, "y": 1 }, { "x": 6, "y": 1 },
              { "x": 8, "y": 1 }, { "x": 9, "y": 1 }, { "x": 7, "y": 1 }, { "x": 5, "y": 1 },
              { "x": 1, "y": 4 }, { "x": 2, "y": 2 }, { "x": 5, "y": 2 }, { "x": 2, "y": 6 },
              { "x": 2, "y": 9 }, { "x": 8, "y": 2 }, { "x": 7, "y": 2 }, { "x": 2, "y": 4 },
              { "x": 2, "y": 3 }, { "x": 3, "y": 3 }, { "x": 4, "y": 3 }, { "x": 97, "y": 1 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Nine",
    "sections": [
      {
        "id": "9a",
        "type": "test",
        "name": "Progress Check II",
        "facts": "Lessons 1-8",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "9a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 7, "y": 7 }, { "x": 8, "y": 8 }, { "x": 4, "y": 4 }, { "x": 6, "y": 4 },
              { "x": 3, "y": 3 }, { "x": 3, "y": 4 }, { "x": 6, "y": 1 }, { "x": 2, "y": 5 },
              { "x": 0, "y": 7 }, { "x": 4, "y": 6 }
            ]
          }
        ]
      },
      {
        "id": "9b",
        "type": "movie",
        "name": "Bouncing in the Back Yard",
        "facts": "7x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "9b",
            "video": "7x8story.mp4"
          }
        ]
      },
      {
        "id": "9c",
        "type": "song",
        "name": "Spunky Boy",
        "facts": "7x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "9c",
            "video": "7x8song.mp4"
          }
        ]
      },
      {
        "id": "9d",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "7x8",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "9d",
            "gameImage": "7x8.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou7x8.mp3" ],
            "x": 7,
            "y": 8
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Ten",
    "sections": [
      {
        "id": "10a",
        "type": "movie",
        "name": "Twins Cross the Desert",
        "facts": "6x6",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "10a",
            "video": "6x6story.mp4"
          }
        ]
      },
      {
        "id": "10b",
        "type": "song",
        "name": "Thirsty Desert Walk",
        "facts": "6x6",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "10b",
            "video": "6x6song.mp4"
          }
        ]
      },
      {
        "id": "10c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "6x6",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "10c",
            "gameImage": "6x6.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou6x6.mp3" ],
            "x": 6,
            "y": 6
          }
        ]
      },
      {
        "id": "10d",
        "type": "slidingpicture",
        "name": "Click the Picture II",
        "facts": "4x4, 6x4, 7x8, 6x6",
        "pages": [
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 7x8?",
            "slideAudio": [ "find7x8.mp3", "box7x8.mp3" ],
            "slideHint": "When it's 7x8, 7 bounces on his trampoline (8) over to the diving board (5) and into the pool (6).",
            "slides": [
              { "image": "4x4.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": false },
              { "image": "7x8.jpg", "isCorrect": true },
              { "image": "6x6.jpg", "isCorrect": false }
            ],
            "x": 7,
            "y": 8
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 4x4?",
            "slideAudio": [ "find4x4.mp3", "box4x4.mp3" ],
            "slideHint": "When it's 4x4, the fours become a 4 by 4 (4x4) and you have to be 16 to drive it.",
            "slides": [
              { "image": "4x4.jpg", "isCorrect": true },
              { "image": "6x4.jpg", "isCorrect": false },
              { "image": "7x8.jpg", "isCorrect": false },
              { "image": "6x6.jpg", "isCorrect": false }
            ],
            "x": 4,
            "y": 4
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 6x6?",
            "slideAudio": [ "find6x6.mp3", "box6x6.mp3" ],
            "slideHint": "When it's 6x6, they are very thirsty sixes (36).",
            "slides": [
              { "image": "4x4.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": false },
              { "image": "7x8.jpg", "isCorrect": false },
              { "image": "6x6.jpg", "isCorrect": true }
            ],
            "x": 6,
            "y": 6
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 6x4?",
            "slideAudio": [ "find6x4.mp3", "box6x4.mp3" ],
            "slideHint": "When it's 6x4, 6 is a snail in a magic pond (4).  The snail gets his wish to become a swan (2), thanks to the magic pond (4).",
            "slides": [
              { "image": "4x4.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": true },
              { "image": "7x8.jpg", "isCorrect": false },
              { "image": "6x6.jpg", "isCorrect": false }
            ],
            "x": 6,
            "y": 4
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "10d",
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 7, "y": 8 }, { "x": 4, "y": 4 },
              { "x": 6, "y": 6 }, { "x": 6, "y": 4 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Eleven",
    "sections": [
      {
        "id": "11a",
        "type": "movie",
        "name": "Fives Alive",
        "facts": "5's",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": false,
            "video": "fives1.mp4"
          },
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": true,
            "progressId": "11a",
            "video": "fives2.mp4"
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": false,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 5, "y": 6 }, { "x": 5, "y": 8 },
              { "x": 5, "y": 2 }, { "x": 5, "y": 3 }
            ]
          }
        ]
      },
      {
        "id": "11b",
        "type": "test",
        "name": "Man Alive Try the Fives I",
        "facts": "5's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "11b",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 1, "y": 5 }, { "x": 5, "y": 5 }, { "x": 9, "y": 5 }, { "x": 5, "y": 4 },
              { "x": 6, "y": 5 }, { "x": 4, "y": 5 }, { "x": 7, "y": 5 }, { "x": 5, "y": 3 },
              { "x": 7, "y": 5 }, { "x": 3, "y": 5 }, { "x": 5, "y": 2 }, { "x": 5, "y": 8 },
              { "x": 5, "y": 1 }, { "x": 5, "y": 9 }, { "x": 5, "y": 6 }, { "x": 5, "y": 7 },
              { "x": 3, "y": 3 }, { "x": 4, "y": 3 }, { "x": 8, "y": 8 }, { "x": 7, "y": 7 }
            ]
          }
        ]
      },
      {
        "id": "11c",
        "type": "movie",
        "name": "Hunter Sees Big Foot",
        "facts": "6x3",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "11c",
            "video": "6x3story.mp4"
          }
        ]
      },
      {
        "id": "11d",
        "type": "song",
        "name": "Stay Put Big Foot",
        "facts": "6x3",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "11d",
            "video": "6x3song.mp4"
          }
        ]
      },
      {
        "id": "11e",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "6x3",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "11e",
            "gameImage": "6x3.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou6x3.mp3" ],
            "x": 6,
            "y": 3
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Twelve",
    "sections": [
      {
        "id": "12a",
        "type": "movie",
        "name": "A Birthday on a Ship",
        "facts": "6x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "12a",
            "video": "6x8story.mp4"
          }
        ]
      },
      {
        "id": "12b",
        "type": "song",
        "name": "Specialty Dish",
        "facts": "6x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "12b",
            "video": "6x8song.mp4"
          }
        ]
      },
      {
        "id": "12c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "6x8",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "12c",
            "gameImage": "6x8.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou6x8.mp3" ],
            "x": 6,
            "y": 8
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Thirteen",
    "sections": [
      {
        "id": "13a",
        "type": "test",
        "name": "Progress Check III",
        "facts": "Lessons 9-12",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "13a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 7, "y": 8 }, { "x": 6, "y": 6 }, { "x": 6, "y": 3 }, { "x": 6, "y": 8 },
              { "x": 6, "y": 5 }, { "x": 5, "y": 9 }, { "x": 8, "y": 7 }, { "x": 8, "y": 6 },
              { "x": 5, "y": 3 }, { "x": 3, "y": 6 }
            ]
          }
        ]
      },
      {
        "id": "13b",
        "type": "movie",
        "name": "The Special Seven Tree",
        "facts": "3x7",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "13b",
            "video": "3x7story.mp4"
          }
        ]
      },
      {
        "id": "13c",
        "type": "song",
        "name": "Spin a Cocoon",
        "facts": "3x7",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "13c",
            "video": "3x7song.mp4"
          }
        ]
      },
      {
        "id": "13d",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "3x7",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "13d",
            "gameImage": "3x7.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou3x7.mp3" ],
            "x": 3,
            "y": 7
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Fourteen",
    "sections": [
      {
        "id": "14a",
        "type": "movie",
        "name": "The Fire at the Farm",
        "facts": "7x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "14a",
            "video": "7x4story.mp4"
          }
        ]
      },
      {
        "id": "14b",
        "type": "song",
        "name": "The Fireman's Bell",
        "facts": "7x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "14b",
            "video": "7x4song.mp4"
          }
        ]
      },
      {
        "id": "14c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "7x4",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "14c",
            "gameImage": "4x7.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou7x4.mp3" ],
            "x": 7,
            "y": 4
          }
        ]
      },
      {
        "id": "14d",
        "type": "test",
        "name": "Man Alive Try The Fives II",
        "facts": "5's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "14d",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 1, "y": 5 }, { "x": 5, "y": 5 }, { "x": 9, "y": 5 }, { "x": 5, "y": 4 },
              { "x": 6, "y": 5 }, { "x": 4, "y": 5 }, { "x": 8, "y": 5 }, { "x": 5, "y": 3 },
              { "x": 7, "y": 5 }, { "x": 3, "y": 5 }, { "x": 5, "y": 2 }, { "x": 5, "y": 8 },
              { "x": 5, "y": 1 }, { "x": 5, "y": 9 }, { "x": 5, "y": 6 }, { "x": 5, "y": 7 },
              { "x": 3, "y": 3 }, { "x": 4, "y": 3 }, { "x": 8, "y": 8 }, { "x": 7, "y": 7 }
            ]
          }
        ]
      },
      {
        "id": "14e",
        "type": "slidingpicture",
        "name": "Click the Picture III",
        "facts": "6x3, 6x8, 3x7, 7x4",
        "pages": [
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 3x7?",
            "slideAudio": [ "find3x7.mp3", "box3x7.mp3" ],
            "slideHint": "When it's 3x7, 3 is a butterfly at the 7 tree.  The caterpillar (2) will soon become a cocoon (1).",
            "slides": [
              { "image": "6x3.jpg", "isCorrect": false },
              { "image": "6x8.jpg", "isCorrect": false },
              { "image": "3x7.jpg", "isCorrect": true },
              { "image": "4x7.jpg", "isCorrect": false }
            ],
            "x": 3,
            "y": 7
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 6x3?",
            "slideAudio": [ "find6x3.mp3", "box6x3.mp3" ],
            "slideHint": "When it's 6x3, 6 shoots her bow (3).  The arrow (1) lands in front of Big Foot (8).",
            "slides": [
              { "image": "6x3.jpg", "isCorrect": true },
              { "image": "6x8.jpg", "isCorrect": false },
              { "image": "3x7.jpg", "isCorrect": false },
              { "image": "4x7.jpg", "isCorrect": false }
            ],
            "x": 6,
            "y": 3
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 7x4?",
            "slideAudio": [ "find7x4.mp3", "box7x4.mp3" ],
            "slideHint": "When it's 7x4, 7 is a fireman and 4 is the fire pole.  They arrive at the fire too (2) late (8).",
            "slides": [
              { "image": "6x3.jpg", "isCorrect": false },
              { "image": "6x8.jpg", "isCorrect": false },
              { "image": "3x7.jpg", "isCorrect": false },
              { "image": "4x7.jpg", "isCorrect": true }
            ],
            "x": 4,
            "y": 7
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 6x8?",
            "slideAudio": [ "find6x8.mp3", "box6x8.mp3" ],
            "slideHint": "When it's 6x8, 6 is the chef and the cake is in the shape of an 8.  The cake is \"for the eight.\" (48)",
            "slides": [
              { "image": "6x3.jpg", "isCorrect": false },
              { "image": "6x8.jpg", "isCorrect": true },
              { "image": "3x7.jpg", "isCorrect": false },
              { "image": "4x7.jpg", "isCorrect": false }
            ],
            "x": 6,
            "y": 8
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "14e",
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 3, "y": 7 }, { "x": 6, "y": 3 },
              { "x": 4, "y": 7 }, { "x": 6, "y": 8 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Fifteen",
    "sections": [
      {
        "id": "15a",
        "type": "movie",
        "name": "The Nifty Nines",
        "facts": "9's",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": true,
            "recordProgress": true,
            "progressId": "15a",
            "video": "nines.mp4"
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": false,
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 9, "y": 3 }, { "x": 9, "y": 4 },
              { "x": 9, "y": 9 }, { "x": 9, "y": 7 }
            ]
          }
        ]
      },
      {
        "id": "15b",
        "type": "test",
        "name": "Nines in a Line I",
        "facts": "9's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "15b",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 9, "y": 5 }, { "x": 8, "y": 9 }, { "x": 4, "y": 9 }, { "x": 9, "y": 9 },
              { "x": 3, "y": 9 }, { "x": 6, "y": 9 }, { "x": 9, "y": 7 }, { "x": 9, "y": 1 },
              { "x": 2, "y": 9 }, { "x": 9, "y": 4 }, { "x": 5, "y": 9 }, { "x": 9, "y": 2 },
              { "x": 9, "y": 8 }, { "x": 9, "y": 6 }, { "x": 7, "y": 9 }, { "x": 9, "y": 3 },
              { "x": 7, "y": 7 }, { "x": 4, "y": 3 }, { "x": 8, "y": 8 }, { "x": 6, "y": 4 }
            ]
          }
        ]
      },
      {
        "id": "15c",
        "type": "movie",
        "name": "How High Can They Jump?",
        "facts": "7x6",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "15c",
            "video": "7x6story.mp4"
          }
        ]
      },
      {
        "id": "15d",
        "type": "song",
        "name": "Jump Like a Kangaroo",
        "facts": "7x6",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "15d",
            "video": "7x6song.mp4"
          }
        ]
      },
      {
        "id": "15e",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "7x6",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "15e",
            "gameImage": "7x6.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou7x6.mp3" ],
            "x": 7,
            "y": 6
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Sixteen",
    "sections": [
      {
        "id": "16a",
        "type": "movie",
        "name": "Mud Pies on a Rainy Day",
        "facts": "8x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "16a",
            "video": "8x4story.mp4"
          }
        ]
      },
      {
        "id": "16b",
        "type": "song",
        "name": "Mud Pies in Their Eyes",
        "facts": "8x4",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "16b",
            "video": "8x4song.mp4"
          }
        ]
      },
      {
        "id": "16c",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "8x4",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "16c",
            "gameImage": "4x8.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou8x4.mp3" ],
            "x": 4,
            "y": 8
          }
        ]
      },
      {
        "id": "16d",
        "type": "test",
        "name": "Nines in a Line II",
        "facts": "9's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "16d",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 9, "y": 5 }, { "x": 8, "y": 9 }, { "x": 4, "y": 9 }, { "x": 9, "y": 9 },
              { "x": 3, "y": 9 }, { "x": 6, "y": 9 }, { "x": 9, "y": 7 }, { "x": 9, "y": 1 },
              { "x": 2, "y": 9 }, { "x": 9, "y": 4 }, { "x": 5, "y": 9 }, { "x": 9, "y": 2 },
              { "x": 9, "y": 8 }, { "x": 9, "y": 6 }, { "x": 7, "y": 9 }, { "x": 9, "y": 3 },
              { "x": 7, "y": 7 }, { "x": 4, "y": 3 }, { "x": 8, "y": 8 }, { "x": 6, "y": 4 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Seventeen",
    "sections": [
      {
        "id": "17a",
        "type": "test",
        "name": "Progress Check IV",
        "facts": "Lessons 13-16",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "17a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": testInstructions,
            "testAudio": "tests.mp3",
            "testQuestions": [
              { "x": 8, "y": 4 }, { "x": 7, "y": 6 }, { "x": 9, "y": 2 }, { "x": 3, "y": 7 },
              { "x": 7, "y": 4 }, { "x": 9, "y": 0 }, { "x": 7, "y": 3 }, { "x": 4, "y": 7 },
              { "x": 4, "y": 8 }, { "x": 6, "y": 7 }
            ]
          }
        ]
      },
      {
        "id": "17b",
        "type": "movie",
        "name": "Big Snake Scares the Bat",
        "facts": "3x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "17b",
            "video": "3x8story.mp4"
          }
        ]
      },
      {
        "id": "17c",
        "type": "song",
        "name": "Black Lagoon",
        "facts": "3x8",
        "pages": [
          {
            "type": "movie",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "17c",
            "video": "3x8song.mp4"
          }
        ]
      },
      {
        "id": "17d",
        "type": "guessinggame",
        "name": "Guessing Game",
        "facts": "3x8",
        "pages": [
          {
            "type": "guessinggame",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "17d",
            "gameImage": "3x8.jpg",
            "gameAudio": [ "guessinggame.mp3", "doyou3x8.mp3" ],
            "x": 3,
            "y": 8
          }
        ]
      },
      {
        "id": "17e",
        "type": "slidingpicture",
        "name": "Click the Picture IV",
        "facts": "7x6, 8x4, 3x8, 6x4",
        "pages": [
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 7x6?",
            "slideAudio": [ "find7x6.mp3", "box7x6.mp3" ],
            "slideHint": "When it's 7x6 they have a high jump (4) contest and land on the cushion (2).",
            "slides": [
              { "image": "3x8.jpg", "isCorrect": false },
              { "image": "4x8.jpg", "isCorrect": false },
              { "image": "7x6.jpg", "isCorrect": true },
              { "image": "6x4.jpg", "isCorrect": false }
            ],
            "x": 6,
            "y": 7
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 3x8?",
            "slideAudio": [ "find3x8.mp3", "box3x8.mp3" ],
            "slideHint": "When it's 3x8, 3 is a bat who flies into the cave (8) and finds the King of Snakes (2) on a throne (4).",
            "slides": [
              { "image": "3x8.jpg", "isCorrect": true },
              { "image": "4x8.jpg", "isCorrect": false },
              { "image": "7x6.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": false }
            ],
            "x": 8,
            "y": 3
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 6x4?",
            "slideAudio": [ "find6x4.mp3", "box6x4.mp3" ],
            "slideHint": "When it's 6x4, 6 is a snail in a magic pond (4).  The snail gets his wish to become a swan (2), thanks to the magic pond (4).",
            "slides": [
              { "image": "3x8.jpg", "isCorrect": false },
              { "image": "4x8.jpg", "isCorrect": false },
              { "image": "7x6.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": true }
            ],
            "x": 6,
            "y": 4
          },
          {
            "type": "slidingpicture",
            "autoAdvance": true,
            "recordProgress": false,
            "slideInstructions": "Do you remember the story for 8x4?",
            "slideAudio": [ "find8x4.mp3", "box8x4.mp3" ],
            "slideHint": "When it's 8x4, (8) is the muddy pig and the (4) is the tub.  The pig says, \"He's dirty too.\"",
            "slides": [
              { "image": "3x8.jpg", "isCorrect": false },
              { "image": "4x8.jpg", "isCorrect": true },
              { "image": "7x6.jpg", "isCorrect": false },
              { "image": "6x4.jpg", "isCorrect": false }
            ],
            "x": 4,
            "y": 8
          },
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "17e",
            "timeTest": false,
            "showInstructions": false,
            "testAudio": "nowtrythese.mp3",
            "testQuestions": [
              { "x": 7, "y": 6 }, { "x": 3, "y": 8 },
              { "x": 6, "y": 4 }, { "x": 8, "y": 4 }
            ]
          }
        ]
      }
    ]
  },
  {
    "name": "Lesson Eighteen",
    "sections": [
      {
        "id": "18a",
        "type": "test",
        "name": "Post-test Nuts and Bolts",
        "facts": "0-9's",
        "pages": [
          {
            "type": "test",
            "autoAdvance": false,
            "recordProgress": true,
            "progressId": "18a",
            "timeTest": true,
            "showInstructions": true,
            "instructions": posttestInstructions,
            "testAudio": "posttest.mp3",
            "testQuestions": [
              { "x": 2, "y": 1 }, { "x": 3, "y": 2 }, { "x": 4, "y": 4 }, { "x": 3, "y": 6 },
              { "x": 7, "y": 8 }, { "x": 8, "y": 9 }, { "x": 1, "y": 3 }, { "x": 2, "y": 4 },
              { "x": 5, "y": 3 }, { "x": 6, "y": 4 }, { "x": 8, "y": 8 }, { "x": 9, "y": 4 },
              { "x": 1, "y": 1 }, { "x": 2, "y": 1 }, { "x": 5, "y": 4 }, { "x": 6, "y": 6 },
              { "x": 9, "y": 3 }, { "x": 6, "y": 9 }, { "x": 7, "y": 4 }, { "x": 2, "y": 9 },
              { "x": 4, "y": 1 }, { "x": 5, "y": 2 }, { "x": 5, "y": 5 }, { "x": 8, "y": 6 },
              { "x": 9, "y": 7 }, { "x": 5, "y": 7 }, { "x": 9, "y": 1 }, { "x": 1, "y": 5 },
              { "x": 0, "y": 7 }, { "x": 3, "y": 7 }, { "x": 6, "y": 1 }, { "x": 6, "y": 7 },
              { "x": 8, "y": 5 }, { "x": 9, "y": 9 }, { "x": 3, "y": 8 }, { "x": 5, "y": 9 },
              { "x": 4, "y": 3 }, { "x": 8, "y": 1 }, { "x": 7, "y": 2 }, { "x": 3, "y": 0 },
              { "x": 7, "y": 7 }, { "x": 1, "y": 7 }, { "x": 2, "y": 6 }, { "x": 8, "y": 4 },
              { "x": 5, "y": 6 }, { "x": 0, "y": 9 }, { "x": 3, "y": 3 }, { "x": 2, "y": 8 }
            ]
          }
        ]
      }
    ]
  }
];
